import React, { useState } from 'react';
import { shape } from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import validator from 'email-validator';

import Field from '../../../stories/Field';
import Container from '../../Container';

const SignUp = (props) => {
  const { content } = props;
  const [resMsg, setResMsg] = useState(null);
  const [filedClassName, setFiledClassName] = useState(null);

  const onChange = async (values) => {
    setResMsg(null);
    setFiledClassName(null);
    if (!validator.validate(values.email)) {
      setResMsg('Enter a valid email address');
      setFiledClassName('error');
    }
    return null;
  };

  const onFinish = async (values) => {
    const { result, msg } = await addToMailchimp(values.email);
    if (result === 'error') {
      if (msg.includes('already subscribed')) {
        setResMsg('This email is already subscribed');
        setFiledClassName('error');
        return null;
      }
      setFiledClassName('error');
      setResMsg('Look like something went wrong, please try again');
      return null;
    }
    if (result === 'success') {
      setResMsg('You\'re in! Keep an eye on your inbox for exclusive Triller Fight Club updates');
      setFiledClassName('success');
      return null;
    }
    setResMsg(null);
    return null;
  };

  return (
    <Container>
      <div className="sign-up">
        {content.backgroundImage && content.backgroundImage.url
          && (
          <img
            className="sign-up-background-img"
            src={content.backgroundImage.url}
            alt={content.backgroundImage.url}
          />
          )}
        <div className="sign-up-content-grid">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="sign-up-content-grid-title-wrapper">
              <div className="col-lg-11 col-mb-11 col-sm-11">
                <span className="sign-up-marquee-line sign-up-marquee-line-lower" />
              </div>
              {content.title && <h2 className="sign-up-content-grid-title">{content.title}</h2>}
              <div className="col-lg-7 col-mb-7 col-sm-7">
                <span className="sign-up-marquee-line" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="sign-up-content-grid-form-wrapper">
              {content.label && <p className="sign-up-content-grid-label">{content.label}</p>}
              <Field
                onFinish={onFinish}
                buttonLabel={content.buttonLabel}
                resMsg={resMsg}
                className={filedClassName}
                onValuesChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

SignUp.propTypes = {
  content: shape().isRequired,
};

export default SignUp;
