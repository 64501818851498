import React from 'react';
import PropTypes, { func, number } from 'prop-types';
import { Pagination } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from './Button';
import './pagination.scss';

const NewsPagination = (props) => {
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <AnchorLink offset="0" href="#pagination-anchor">
          <Button mode="arrow-left" />
        </AnchorLink>
      );
    }
    if (type === 'next') {
      return (
        <AnchorLink offset="0" href="#pagination-anchor">
          <Button mode="arrow-right" />
        </AnchorLink>
      );
    }
    return (
      <AnchorLink offset="0" href="#pagination-anchor">
        {originalElement}
      </AnchorLink>
    );
  };

  return (
    <Pagination
      className="tr-pagination"
      defaultCurrent={1}
      total={props.total}
      itemRender={itemRender}
      showLessItems={props.showLessItems}
      showSizeChanger
      onChange={props.clickPagination}
      defaultPageSize={props.defaultPageSize}
      pageSize={props.pageSize}
    />
  );
};

NewsPagination.defaultProps = {
  clickPagination: () => {},
  total: null,
  defaultPageSize: 4,
  pageSize: 4,
};

NewsPagination.propTypes = {
  showLessItems: PropTypes.bool.isRequired,
  clickPagination: func,
  total: number,
  defaultPageSize: number,
  pageSize: number,
};

export default NewsPagination;
