import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { bool, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import ButtonGroup from '../../../stories/ButtonGroup';

const { Text } = Typography;

const EventBanner = ({ content, isLast }) => {
  const color = content.color?.hex;
  const inlineStyle = color ? { backgroundColor: color } : {};

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (singleButton) => {
    let path = singleButton.data?.path || singleButton?.path;
    if (!isEmpty(singleButton.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${singleButton.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${singleButton.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${singleButton.offerIdEverflow}&${params}`;
      }
    }

    return (
      <a
        className="tr-button tr-button-group-btn"
        target={(singleButton.data?.openInNewWindow
          || singleButton.openInNewWindow) && '_blank'}
        href={path}
      >
        {singleButton.label || singleButton.data?.label}
      </a>
    );
  };

  return (
    <>
      {content.data && content.data.event && (
      <div
        className={`tr-event-banner ${isLast ? 'last-position' : ''}`}
        style={inlineStyle}
      >
        <div className="tr-event-banner-container">
          <div className="tr-event-banner-info">
            {!isEmpty(content.data.event.data) && (
              <div className="tr-event-banner-info-head">
                <Text className="tr-event-banner-info-day">{`${content.data.event.data.dateDayOfWeek},`}</Text>
                <Text className="tr-event-banner-info-month">{content.data.event.data.dateMonth}</Text>
                <Text className="tr-event-banner-info-date">{content.data.event.data.date}</Text>
                <Text className="tr-event-banner-info-date">-</Text>
                <Text className="tr-event-banner-info-time">{content.data.event.data.dateClock}</Text>
                <p className="tr-event-banner-info-timezone">{content.data.event.data.dateTimezone}</p>
              </div>
            )}
            {content.data.event
            && content.data.event.data
            && (
              <h2 className="tr-event-banner-info-title">
                {content.data.event.label
                  || content.data.event.data.title
                  || ''}
              </h2>
            )}
            {!isEmpty(content.data)
              && !isEmpty(content.data.bannerLinks)
              && content.data.bannerLinks.length >= 2 ? (
                <div className="tr-events-banner tr-button-double tr-button-group">
                  <ButtonGroup
                    onClickPrimary={async () => {
                      await window.EF.click({
                        offer_id: content.data.bannerLinks[0].offerIdEverflow,
                        affiliate_id: window.EF.urlParameter('affid') || 1643,
                        gclid: window.EF.urlParameter('gclid'),
                        fbclid: window.EF.urlParameter('fbclid'),
                        sub1: window.EF.urlParameter('utm_content'),
                        sub2: window.EF.urlParameter('utm_term'),
                        sub3: window.EF.urlParameter('utm_campaign'),
                      });
                    }}
                    onClickSecondary={async () => {
                      await window.EF.click({
                        offer_id: content.data.bannerLinks[1].offerIdEverflow,
                        affiliate_id: window.EF.urlParameter('affid') || 1643,
                        gclid: window.EF.urlParameter('gclid'),
                        fbclid: window.EF.urlParameter('fbclid'),
                        sub1: window.EF.urlParameter('utm_content'),
                        sub2: window.EF.urlParameter('utm_term'),
                        sub3: window.EF.urlParameter('utm_campaign'),
                      });
                    }}
                    offerIdEverflowPrimary={content.data.bannerLinks[0].offerIdEverflow}
                    offerIdEverflowSecondary={content.data.bannerLinks[1].offerIdEverflow}
                    hrefPrimary={content.data.bannerLinks[0].data?.path
                      || content.data.bannerLinks[0].path}
                    openInNewWindowPrimary={content.data.bannerLinks[0].data?.openInNewWindow
                      || content.data.bannerLinks[0].openInNewWindow}
                    labelPrimary={content.data.bannerLinks[0].label
                            || content.data.bannerLinks[0].data?.label}
                    hrefSecondary={content.data.bannerLinks[1].data?.path
                      || content.data.bannerLinks[1].path}
                    openInNewWindowSecondary={content.data.bannerLinks[1].data?.openInNewWindow
                      || content.data.bannerLinks[1].openInNewWindow}
                    labelSecondary={content.data.bannerLinks[1].label
                            || content.data.bannerLinks[1].data?.label}
                  />
                </div>
              ) : (
                <div
                  className="tr-events-banner tr-button-single tr-button-group"
                  role="button"
                  onKeyDown={null}
                  tabIndex={0}
                  onClick={async () => {
                    await window.EF.click({
                      offer_id: content.data.bannerLinks[0].offerIdEverflow,
                      affiliate_id: window.EF.urlParameter('affid') || 1643,
                      gclid: window.EF.urlParameter('gclid'),
                      fbclid: window.EF.urlParameter('fbclid'),
                      sub1: window.EF.urlParameter('utm_content'),
                      sub2: window.EF.urlParameter('utm_term'),
                      sub3: window.EF.urlParameter('utm_campaign'),
                    });
                  }}
                >
                  {renderButton(content.data.bannerLinks[0])}
                </div>
              )}
            {content.mobileBackground
            && content.mobileBackground.url
            && (
              <img src={content.mobileBackground.url} alt="" className="tr-event-banner-info-background-image" />
            )}
          </div>
          {content.data.event
          && content.data.event.data
          && content.data.event.data.image
          && (
            <img
              src={content.data.event.data.image.url}
              alt=""
              className=" tr-event-banner-image"
            />
          )}
        </div>
      </div>
      )}
    </>
  );
};

EventBanner.propTypes = {
  content: shape().isRequired,
  isLast: bool.isRequired,
};

export default EventBanner;
