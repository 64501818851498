import React from 'react';
import { shape } from 'prop-types';
import { Typography } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { navigate } from 'gatsby';

import Container from '../../Container';
import EventsList from '../../EventsList/EventsList';

const { Text } = Typography;

const HeroNews = ({ content }) => {
  const { data } = content.mainNews || {};

  const navigateToEvent = (path) => () => {
    navigate(path);
  };

  return (
    <Container id="hero-news-container">
      {!isEmpty(data) && (
        <div className="hero-news main-content news-content">
          <div
            className="main-content-img-container"
            style={{ backgroundImage: `url(${data.primaryImage?.url})` }}
            onClick={navigateToEvent(data.slug)}
            role="presentation"
          >
            <img className="main-content-img" src={data.secondaryImage?.url} alt="" />
          </div>
          <div className="news-content">
            <div className="news-content-head">
              {!isEmpty(data.type) && (
                <Text className="news-content-category text-white">{data.type.label}</Text>
              )}
              {!isEmpty(data.date) && (
                <Text className="news-content-date text-white">{`${moment(data.date).format('MM.DD.YY')}`}</Text>
              )}
            </div>
            <h1
              className="news-content-title"
              onClick={navigateToEvent(data && data.slug)}
              role="presentation"
            >
              {data.title.slice(0, 31)}
              {data.title.length > 30 ? '...' : ''}
            </h1>
            {!isEmpty(data.description) && (
              <Text className="news-content-description text-grey">
                {data.description.slice(0, 261)}
                {data.description.length > 260 ? '...' : ''}
              </Text>
            )}
          </div>
        </div>
      )}
      {!isEmpty(content.relatedNews)
        && (
          <EventsList
            events={content.relatedNews}
            newsBackgroundImage={content.newsBackgroundImage}
            button={content.button}
            navigateToEvent={navigateToEvent}
          />
        )}
    </Container>
  );
};

HeroNews.propTypes = {
  content: shape().isRequired,
};

export default HeroNews;
