import React, { useState, useEffect } from 'react';
import { shape, node, number } from 'prop-types';
import { Typography } from 'antd';
import Slider from 'react-slick';
import { isEmpty } from 'lodash';
import Marquee from 'react-fast-marquee';

import Container from '../../Container';
import SvgIcon from '../../../stories/SvgIcon';
import ButtonGroup from '../../../stories/ButtonGroup';
import { marqueeSpeed } from '../../../utils/constants';

const { Text } = Typography;

const SlickButtonFix = ({
  currentSlide,
  slideCount,
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <span {...props}>{children}</span>
);

const EventsBanner = (props) => {
  const { content } = props;

  const filteredSlides = !isEmpty(content.slides)
    && content.slides.filter((s) => !isEmpty(s)
      && !isEmpty(s.event)
      && !isEmpty(s.event.data));

  const [bannerInfo, setBannerInfo] = useState(filteredSlides && filteredSlides[0]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(!isActive);
  }, [bannerInfo]);

  const [firstLink, secondLink] = !isEmpty(bannerInfo) && !isEmpty(bannerInfo.bannerLinks)
    && bannerInfo.bannerLinks;
  let link;
  if (isEmpty(firstLink) && !isEmpty(secondLink)) link = secondLink;
  if (!isEmpty(firstLink) && isEmpty(secondLink)) link = firstLink;
  const color = content.color?.hex;
  const inlineStyle = color ? { backgroundColor: color } : {};

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (item, className) => {
    let path = item.data?.path || item?.path;
    if (!isEmpty(item.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${item.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${item.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${item.offerIdEverflow}&${params}`;
      }
    }

    return (
      <a
        className={className}
        target={(item.data?.openInNewWindow || item.openInNewWindow) && '_blank'}
        href={path}
      >
        {item.label || item.data?.label}
      </a>
    );
  };

  return (
    <div className="overflow-hidden event-banner" style={inlineStyle}>
      <span className="event-banner-marquee-line" />
      {content && content.backgroundText && (
        <Marquee speed={marqueeSpeed}>
          <p className="event-banner-background-text">
            {(` ${content.backgroundText} `).repeat(30)}
          </p>
        </Marquee>
      )}
      <Container gap="md">
        <div className="event-banner-wrapper">
          <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
            <div className={`event-banner-info ${isActive ? 'active' : ''}`}>
              <div className="animated-fast fadein-text">
                {!isEmpty(bannerInfo.event) && !isEmpty(bannerInfo.event.data)
                  && (
                    <>
                      <div className="event-banner-info-head">
                        <Text className="event-banner-info-day">{`${bannerInfo.event.data.dateDayOfWeek},`}</Text>
                        <Text className="event-banner-info-month">{bannerInfo.event.data.dateMonth}</Text>
                        <Text className="event-banner-info-date">{bannerInfo.event.data.date}</Text>
                        <Text className="event-banner-info-date">-</Text>
                        <Text className="event-banner-info-time">{bannerInfo.event.data.dateClock}</Text>
                        <p className="event-banner-info-timezone">{bannerInfo.event.data.dateTimezone}</p>
                      </div>
                      <h2 className="event-banner-info-title">
                        {bannerInfo.event.label || bannerInfo.event.data.title}
                      </h2>
                    </>
                  )}
              </div>

              {!isEmpty(firstLink)
                && !isEmpty(secondLink)
                && ((!isEmpty(firstLink.data) && !isEmpty(firstLink.data.path)) || !isEmpty(firstLink.path))
                && ((!isEmpty(secondLink.data) && !isEmpty(secondLink.data.path)) || !isEmpty(secondLink.path))
                && (
                  <div className="events-bunner button-double tr-button-group animated-slow fadein-btn">
                    <ButtonGroup
                      mode="filled-light"
                      labelPrimary={firstLink.label || firstLink.data?.label}
                      hrefPrimary={firstLink.data?.path || firstLink.path}
                      openInNewWindowPrimary={firstLink.data?.openInNewWindow || firstLink.openInNewWindow}
                      labelSecondary={secondLink.label || secondLink.data?.label}
                      hrefSecondary={secondLink.data?.path || secondLink.path}
                      openInNewWindowSecondary={secondLink.data?.openInNewWindow || secondLink.openInNewWindow}
                      offerIdEverflowPrimary={firstLink.offerIdEverflow}
                      offerIdEverflowSecondary={secondLink.offerIdEverflow}
                      onClickPrimary={async () => {
                        await window.EF.click({
                          offer_id: firstLink.offerIdEverflow,
                          affiliate_id: window.EF.urlParameter('affid') || 1643,
                          gclid: window.EF.urlParameter('gclid'),
                          fbclid: window.EF.urlParameter('fbclid'),
                          sub1: window.EF.urlParameter('utm_content'),
                          sub2: window.EF.urlParameter('utm_term'),
                          sub3: window.EF.urlParameter('utm_campaign'),
                        });
                      }}
                      onClickSecondary={async () => {
                        await window.EF.click({
                          offer_id: secondLink.offerIdEverflow,
                          affiliate_id: window.EF.urlParameter('affid') || 1643,
                          gclid: window.EF.urlParameter('gclid'),
                          fbclid: window.EF.urlParameter('fbclid'),
                          sub1: window.EF.urlParameter('utm_content'),
                          sub2: window.EF.urlParameter('utm_term'),
                          sub3: window.EF.urlParameter('utm_campaign'),
                        });
                      }}
                    />
                  </div>
                )}
              {(!isEmpty(link))
                && ((!isEmpty(link.data) && !isEmpty(link.data.path)) || !isEmpty(link.path))
                && (
                  <div
                    className="events-bunner button-single animated-slow fadein-btn"
                    role="button"
                    onKeyDown={null}
                    tabIndex={0}
                    onClick={async () => {
                      await window.EF.click({
                        offer_id: link.offerIdEverflow,
                        affiliate_id: window.EF.urlParameter('affid') || 1643,
                        gclid: window.EF.urlParameter('gclid'),
                        fbclid: window.EF.urlParameter('fbclid'),
                        sub1: window.EF.urlParameter('utm_content'),
                        sub2: window.EF.urlParameter('utm_term'),
                        sub3: window.EF.urlParameter('utm_campaign'),
                      });
                    }}
                  >
                    {renderButton(link, 'tr-button tr-button-group-btn')}
                  </div>
                )}
            </div>
          </div>
          <Slider
            className="slick-dots-below"
            autoplay={filteredSlides.length !== 1}
            autoplaySpeed={4750}
            speed={1500}
            dots
            infinite
            slidesToShow={1}
            slidesToScroll={1}
            pauseOnHover
            nextArrow={(
              <SlickButtonFix>
                <SvgIcon mode="ArrowRight" color="#ed1849" />
              </SlickButtonFix>
          )}
            prevArrow={(
              <SlickButtonFix>
                <SvgIcon mode="ArrowLeft" color="#ed1849" />
              </SlickButtonFix>
          )}
            beforeChange={(current, next) => setBannerInfo(filteredSlides[next], setIsActive(!isActive))}
          >
            {!isEmpty(content) && !isEmpty(filteredSlides)
              && filteredSlides.map((banner) => !isEmpty(banner.event)
                && !isEmpty(banner.event.data)
                && (
                <div key={banner.id} className="event-banner-slide">
                  {!isEmpty(banner.event.data.image) && (
                    <img src={banner.event.data.image.url} alt="" className="event-banner-image" />
                  )}
                </div>
                ))}
          </Slider>
        </div>
        {!isEmpty(content.button)
          && (!isEmpty(content.button.label) || (!isEmpty(content.button.data) && !isEmpty(content.button.data.label)))
          && (!isEmpty(content.button.path) || (!isEmpty(content.button.data) && !isEmpty(content.button.data.path)))
          && (
            <div
              className="events-bunner button-single"
              role="button"
              onKeyDown={null}
              tabIndex={0}
              onClick={async () => {
                await window.EF.click({
                  offer_id: content.button.offerIdEverflow,
                  affiliate_id: window.EF.urlParameter('affid') || 1643,
                  gclid: window.EF.urlParameter('gclid'),
                  fbclid: window.EF.urlParameter('fbclid'),
                  sub1: window.EF.urlParameter('utm_content'),
                  sub2: window.EF.urlParameter('utm_term'),
                  sub3: window.EF.urlParameter('utm_campaign'),
                });
              }}
            >
              {renderButton(content.button, 'tr-button tr-button-filled-light')}
            </div>
          )}
      </Container>
    </div>
  );
};

EventsBanner.propTypes = {
  content: shape().isRequired,
};

SlickButtonFix.propTypes = {
  children: node.isRequired,
  currentSlide: node,
  slideCount: number,
};

SlickButtonFix.defaultProps = {
  currentSlide: null,
  slideCount: 0,
};

export default EventsBanner;
