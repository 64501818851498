/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const Facebook = ({
  colorPrimary, width, height, background,
}) => (
  <svg width={width} height={height} viewBox="0 0 28 28" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1017.000000, -4371.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="4532" />
        <g transform="translate(0.000000, 3803.000000)">
          <rect fill={colorPrimary} x="0" y="390" width="1443" height="338" />
          <g transform="translate(1017.000000, 568.000000)" stroke={background} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.04">
            <path d="M25.7813408,1 L2.58178771,1 C1.70758637,1 1,1.69784 1,2.56 L1,25.44 C1,26.30216 1.70758637,27 2.58178771,27 L14.7088268,27 L14.7088268,18.68 L11.5452514,18.68 L11.5452514,14.52 L14.7088268,14.52 L14.7088268,11.4 C14.7088268,8.52856 17.0699086,6.2 19.9814525,6.2 L23.1450279,6.2 L23.1450279,10.36 L21.0359777,10.36 C19.8717819,10.36 18.9269274,11.29184 18.9269274,12.44 L18.9269274,14.52 L23.1450279,14.52 L22.6177654,18.68 L18.9269274,18.68 L18.9269274,27 L25.7813408,27 C26.6555421,27 27.3631285,26.30216 27.3631285,25.44 L27.3631285,2.56 C27.3631285,1.69784 26.6555421,1 25.7813408,1 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Facebook.defaultProps = {
  colorPrimary: '#1D1A1D',
  width: 28,
  height: 28,
  background: '#FFFFFF',
};

Facebook.propTypes = {
  colorPrimary: string,
  width: number,
  height: number,
  background: string,
};

export default Facebook;
