import React from 'react';
import { string, func } from 'prop-types';
import SvgIcon from './SvgIcon';

import './button.scss';

const Button = ({ mode, label, onClick }) => (
  <button onClick={onClick} type="button" className={mode ? `tr-button tr-button-${mode}` : 'tr-button'}>
    {mode === 'arrow-left' ? <SvgIcon mode="ArrowLeft" /> : null}
    {mode === 'arrow-right' ? <SvgIcon mode="ArrowRight" /> : null}
    {mode === 'expand' ? <SvgIcon mode="Expand" /> : null}
    {label}
  </button>
);

Button.defaultProps = {
  label: '',
  onClick: func,
};

Button.propTypes = {
  mode: string.isRequired,
  label: string,
  onClick: func,
};

export default Button;
