import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import Marquee from 'react-fast-marquee';
import { navigate } from 'gatsby';

import Container from '../../Container';
import FeaturedNews from '../../FeaturedNews';
import NewsList from '../../NewsList';
import { marqueeSpeed } from '../../../utils/constants';

const NewsBanner = ({ content, data, eventBannersBetweenNews }) => {
  const uniqueNews = !isEmpty(data)
    && !isEmpty(data.news)
    && !isEmpty(content.relatedNews)
    && data.news.filter((news) => !content.relatedNews
      .some((relatedNews) => relatedNews.data?.id === news.id));

  const newsToShow = content.numberOfNews
    && !isEmpty(uniqueNews)
    && uniqueNews.slice(0, content.numberOfNews);

  const navigateToNews = (path) => () => {
    navigate(path);
  };
  const color = content.color?.hex;
  const inlineStyle = color ? { backgroundColor: color } : {};

  return (
    <div className="news-banner">
      <div className="featured-news-wrap" style={inlineStyle}>
        {!isEmpty(content) && !isEmpty(content.backgroundText) && (
          <>
            <span className="news-banner-marquee-line" />
            <Marquee speed={marqueeSpeed} style={inlineStyle}>
              <p className="event-banner-background-text">
                {(` ${content.backgroundText} `).repeat(30)}
              </p>
            </Marquee>
          </>
        )}
        <Container gap="md">
          <FeaturedNews
            relatedNews={content.relatedNews}
            navigateToNews={navigateToNews}
          />
        </Container>
      </div>
      <Container gap="md">
        <NewsList
          numberOfNews={content.numberOfNews}
          news={newsToShow || uniqueNews}
          navigateToNews={navigateToNews}
          button={content.button}
          banners={eventBannersBetweenNews}
        />
      </Container>
    </div>
  );
};

NewsBanner.propTypes = {
  data: shape().isRequired,
  content: shape().isRequired,
  eventBannersBetweenNews: arrayOf(shape()),
};

NewsBanner.defaultProps = {
  eventBannersBetweenNews: null,
};

export default NewsBanner;
