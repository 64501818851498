/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const ArrowLeft = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 41 30"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    >
      <g
        className="arrow-icon-fill"
        transform="translate(-547.000000, -2988.000000)"
        fill={color || '#D8d8d8'}
        fillRule="nonzero"
      >
        <g
          transform="translate(547.000000, 2980.000000)"
        >
          <g
            transform="translate(20.500000, 23.000000) rotate(90.000000) translate(-20.500000, -23.000000) translate(5.500000, 2.500000)"
          >
            <polygon
              points="30 25.650625 28.3375 23.908125 16.1875 36.3490625 16.1875 0 13.8125 0 13.8125 36.3490625 1.65 23.908125 0 25.650625 15 41"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowLeft.defaultProps = {
  color: '',
  width: 41,
  height: 30,
};

ArrowLeft.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default ArrowLeft;
