/* eslint-disable max-len */
import React from 'react';
import { string, number } from 'prop-types';

const CloseBurger = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-277.000000, -13.000000)" fill={color}>
        <g transform="translate(277.290000, 13.290000)">
          <polygon points="25.4200001 1.42 24.0000001 0 12.71 11.3 1.42 0 0 1.42 11.3 12.71 0 24.0000001 1.42 25.4200001 12.71 14.12 24.0000001 25.4200001 25.4200001 24.0000001 14.12 12.71" />
        </g>
      </g>
    </g>
  </svg>
);

CloseBurger.defaultProps = {
  color: '#ED1849',
  width: 26,
  height: 26,
};

CloseBurger.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default CloseBurger;
