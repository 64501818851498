import React from 'react';
import { shape, arrayOf } from 'prop-types';
import { isEmpty } from 'lodash';

import Container from '../../Container';
import NewsContent from '../../NewsContent';
import HeroEventFirst from '../HeroEventFirst';

const NewsHeroBlack = ({ content, data, eventBannersBetweenNews }) => {
  const contentNews = !isEmpty(content)
    && !isEmpty(content.relatedEvent)
    && !isEmpty(content.relatedEvent.data)
    && content.relatedEvent.data.additionalNews;

  return (
    <div className="all-news-container">
      {!isEmpty(content) && !isEmpty(content.relatedEvent.data) && (
        <HeroEventFirst content={content} data={data} />
      )}
      <Container gap="md">
        {!isEmpty(content) && (
          <NewsContent
            content={{ ...content, news: contentNews }}
            data={data}
            eventBannersBetweenNews={eventBannersBetweenNews}
          />
        )}
      </Container>
    </div>
  );
};

NewsHeroBlack.propTypes = {
  data: shape().isRequired,
  content: shape().isRequired,
  eventBannersBetweenNews: arrayOf(shape()),
};

NewsHeroBlack.defaultProps = {
  eventBannersBetweenNews: [],
};

export default NewsHeroBlack;
