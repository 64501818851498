import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import Marquee from 'react-fast-marquee';
import { navigate } from 'gatsby';

import Container from '../../Container';
import FeaturedNews from '../../FeaturedNews';
import SingleFeaturedBanner from '../../SingleFeaturedBanner';
import NewsContent from '../../NewsContent';
import { marqueeSpeed } from '../../../utils/constants';

const NewsSections = ({ content, data, eventBannersBetweenNews }) => {
  const navigateToNews = (path) => () => {
    navigate(path);
  };

  return (
    <div className="all-news-container news-banner">
      {!isEmpty(content.backgroundText)
        && (
        <>
          <span className="all-news-marquee-line " />
          <Marquee speed={marqueeSpeed}>
            <p className="all-news-background-text ">
              {(` ${content.backgroundText} `).repeat(30)}
            </p>
          </Marquee>
        </>
        )}
      <Container gap="md">
        {!isEmpty(content) && !isEmpty(content.news) && content.news.length > 1 && (
          <FeaturedNews
            relatedNews={content.news}
            navigateToNews={navigateToNews}
          />
        )}
        {!isEmpty(content) && !isEmpty(content.news) && content.news.length === 1 && (
          <SingleFeaturedBanner
            content={content.news[0]}
            redirect={navigateToNews}
          />
        )}
        {!isEmpty(content) && (
          <NewsContent
            content={content}
            data={data}
            eventBannersBetweenNews={eventBannersBetweenNews}
          />
        )}
      </Container>
    </div>
  );
};

NewsSections.propTypes = {
  data: shape().isRequired,
  content: shape().isRequired,
  eventBannersBetweenNews: arrayOf(shape()),
};

NewsSections.defaultProps = {
  eventBannersBetweenNews: [],
};

export default NewsSections;
