/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const TwitterIcon = ({
  colorPrimary, width, height, background,
}) => (
  <svg width={width} height={height} viewBox="0 0 32 26" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1075.000000, -3129.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="3288" />
        <g transform="translate(-1.000000, 2560.000000)">
          <rect fill={colorPrimary} x="0" y="390" width="1443" height="338" />
          <g transform="translate(1076.000000, 569.000000)" stroke={background} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.04">
            <path d="M2.97952109,2.24347766 C5.52118012,5.28434574 10.071671,8.15462102 16.1787709,8.46394893 C15.8416976,7.14339622 15.8092464,3.55786437 18.4723356,2.06877418 C19.6636074,1.40181799 20.7910237,1 22.118381,1 C23.786999,1 25.1572757,1.53849776 26.6144377,2.85082914 C27.7994286,2.68845768 30.0155292,1.79130396 30.5410287,1.43881402 C30.1987212,2.675098 28.8294914,4.26284426 27.8402543,4.63999823 C28.7488869,4.63794289 30.745157,4.12616449 31.3575419,3.82403025 C30.5085775,5.11066993 29.026292,6.34181558 28.2150129,6.85256632 C29.1540031,14.7769103 21.6609243,24.6260754 10.8986523,24.6363636 C7.82521287,24.6394351 4.48588326,24.0238623 1,22.1483691 C4.43877673,22.5275784 8.12355423,21.4773023 10.2391609,19.6603862 C7.5708376,19.6028368 4.86901637,17.9082131 3.64005933,15.306159 C4.97683799,15.6977004 6.20893547,15.8970678 6.93961009,15.306159 C5.69390406,15.1746176 1.65949143,13.0915356 1.65949143,9.05794074 C2.58696667,9.78141865 3.9216517,10.5285329 4.960089,10.3301931 C3.26216028,9.381656 0.793778077,5.77351546 2.97952109,2.24347766 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

TwitterIcon.defaultProps = {
  colorPrimary: '#1D1A1D',
  width: 32,
  height: 26,
  background: '#FFFFFF',
};

TwitterIcon.propTypes = {
  colorPrimary: string,
  width: number,
  height: number,
  background: string,
};

export default TwitterIcon;
