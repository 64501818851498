import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import { Typography } from 'antd';
import moment from 'moment';

import SvgIcon from '../../stories/SvgIcon';

const { Text } = Typography;

const EventsList = ({
  events, newsBackgroundImage, button, navigateToEvent,
}) => {
  const eventsToShow = events && events.slice(0, 3);

  const getDate = (data) => moment(data).format('MM.DD.YY');

  return (
    <div className="events-list-wrapper" style={{ backgroundImage: `url(${newsBackgroundImage?.url})` }}>
      <div className="events-list square-card rectangular-card">
        {!isEmpty(eventsToShow) && eventsToShow.map((oneEvents, index) => (
          !isEmpty(oneEvents) && !isEmpty(oneEvents.data) && !isEmpty(oneEvents.data.title)
          && !isEmpty(oneEvents.data.slug)
          && ((index === 2)
            ? (
              <div className="col-lg-6" key={oneEvents.data.id}>
                <div className="rectangular-card" onClick={navigateToEvent(oneEvents.data.slug)} role="presentation">
                  <div className="rectangular-card-content">
                    <div className="rectangular-card-head">
                      {!isEmpty(oneEvents.data) && !isEmpty(oneEvents.data.type) && !isEmpty(oneEvents.data.type.label)
                      && (
                        <Text className="rectangular-card-category text-white">{oneEvents.data.type.label}</Text>
                      )}
                      {!isEmpty(oneEvents.data) && !isEmpty(oneEvents.data.date) && (
                        <Text className="rectangular-card-date text-white text-opacity">
                          {getDate(oneEvents.data.date)}
                        </Text>
                      )}
                    </div>
                    {!isEmpty(oneEvents.data.title)
                    && <h2 className="rectangular-card-title">{oneEvents.data.title}</h2>}
                  </div>
                  <div className="rectangular-card-container">
                    {(!isEmpty(oneEvents.data.commonImage)
                      || !isEmpty(oneEvents.data.primaryImage)
                      || !isEmpty(oneEvents.data.secondaryImage)) && (
                      <img
                        className="rectangular-card-img"
                        src={oneEvents.data.commonImage?.url
                          || oneEvents.data.primaryImage?.url
                          || oneEvents.data.secondaryImage?.url}
                        alt={oneEvents.data.title}
                      />
                    )}
                    <div className="rectangular-card-icon">
                      {!isEmpty(oneEvents.data)
                        && !isEmpty(oneEvents.data.type)
                        && !isEmpty(oneEvents.data.type.label)
                        && (
                          (oneEvents.data.type.label.toLowerCase() === 'videos' && <SvgIcon mode="Play" />)
                          || (oneEvents.data.type.label.toLowerCase() === 'podcast' && <SvgIcon mode="Podcast" />)
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )
            : (
              <div className="col-lg-3" key={oneEvents.data.id}>
                <div className="square-card" onClick={navigateToEvent(oneEvents.data.slug)} role="presentation">
                  <div className="square-card-head">
                    {!isEmpty(oneEvents.data) && !isEmpty(oneEvents.data.type) && !isEmpty(oneEvents.data.type.label)
                      && (
                      <Text className="square-card-category text-white">{oneEvents.data.type.label}</Text>)}
                    {!isEmpty(oneEvents.data) && !isEmpty(oneEvents.data.date) && (
                      <Text className="square-card-date text-white text-opacity">
                        {getDate(oneEvents.data.date)}
                      </Text>
                    )}
                  </div>
                  <h2 className="square-card-title">{oneEvents.data.title}</h2>
                </div>
              </div>
            ))
        ))}
      </div>
      {button
        && (!isEmpty(button.path) || !isEmpty(button.data?.path))
        && (!isEmpty(button.label) || !isEmpty(button.data?.label))
        && (
          <div
            className="events-list-redirect"
            onClick={navigateToEvent(button.path || button.data?.path)}
            role="presentation"
          >
            <p className="events-list-button">{button.label || button.data?.label}</p>
          </div>
        )}
    </div>
  );
};

EventsList.propTypes = {
  events: arrayOf(shape()),
  newsBackgroundImage: shape().isRequired,
  button: shape(),
  navigateToEvent: func.isRequired,
};

EventsList.defaultProps = {
  events: [],
  button: {},
};

export default EventsList;
