import React from 'react';
import NewsBanner from './DynamicComponents/NewsBanner';
import EventsBanner from './DynamicComponents/EventsBanner';
import NotFound from './NotFound';
import NewsSections from './DynamicComponents/NewsSecions';
import EventBanner from './DynamicComponents/BannerWithOneEvent';
import NewsContent from './DynamicComponents/NewsContent';
import FightCard from './DynamicComponents/FightCard';
import HeroNews from './DynamicComponents/HeroNews';
import HeroEventSecond from './DynamicComponents/HeroEventSecond';
import HeroEventFirst from './DynamicComponents/HeroEventFirst';
import DynamicPageContent from './DynamicComponents/DynamicPageContent';
import NewsHeroBlack from './DynamicComponents/NewsHeroBlack';

const DynamicZone = ({
  components = [],
  data = {},
  eventBannersBetweenNews,
}) => components.map((c) => {
  switch (c.typename) {
    case 'STRAPI_ComponentSectionsNewsBanner':
      return (
        <NewsBanner
          key={`${c.typename}_${c.id}`}
          content={c}
          data={data}
          eventBannersBetweenNews={eventBannersBetweenNews}
        />
      );
    case 'STRAPI_ComponentSectionsEventBanner':
      return <EventsBanner key={`${c.typename}_${c.id}`} content={c} data={data} />;
    case 'STRAPI_ComponentSectionsHomePageNewsHero':
      return <HeroNews key={`${c.typename}_${c.id}`} content={c} data={data} />;
    case 'STRAPI_ComponentSectionsHomePageSpecialEventV1Hero':
      return <HeroEventFirst key={`${c.typename}_${c.id}`} content={c} data={data} />;
    case 'STRAPI_ComponentSectionsHomePageSpecialEventV2Hero':
      return <HeroEventSecond key={`${c.typename}_${c.id}`} content={c} data={data} />;
    case 'STRAPI_ComponentSectionsNotFoundPageContent':
      return <NotFound key={`${c.typename}_${c.id}`} content={c} />;
    case 'STRAPI_ComponentSectionsNewsHero':
      return (
        <NewsSections
          key={`${c.typename}_${c.id}`}
          content={c}
          data={data}
          eventBannersBetweenNews={eventBannersBetweenNews}
        />
      );
    case 'STRAPI_ComponentSectionsNewsHeroBlack':
      return (
        <NewsHeroBlack
          key={`${c.typename}_${c.id}`}
          content={c}
          data={data}
          eventBannersBetweenNews={eventBannersBetweenNews}
        />
      );
    case 'STRAPI_ComponentSectionsNewsContent':
      return <NewsContent key={`${c.typename}_${c.id}`} content={c} data={data} />;
    case 'STRAPI_ComponentSectionsContentDynamicPage':
      return <DynamicPageContent key={`${c.typename}_${c.id}`} content={c} />;
    case 'STRAPI_ComponentSectionsBannerWithOneEvent':
      return <EventBanner key={`${c.typename}_${c.id}`} content={c} />;
    case 'STRAPI_ComponentSectionsFightCardSection':
      return <FightCard key={`${c.typename}_${c.id}`} content={c} />;
    default:
      return null;
  }
});

export default DynamicZone;
