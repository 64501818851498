import React from 'react';
import ReactMarkdown from 'react-markdown';
import { string } from 'prop-types';
import { isEmpty } from 'lodash';
import SvgIcon from '../../../stories/SvgIcon';

const Markdown = ({ className, text }) => {
  const quotesRenderer = (data) => {
    const quotes = data.children[1].props?.children[0];
    return (
      <div className="quotes-container">
        {quotes && (
        <div className="quotes-content">
          <SvgIcon mode="Quotes" className="quotes-img-left" />
          <p className="quotes-description">
            {quotes}
            <SvgIcon mode="Quotes" className="quotes-img-right" />
          </p>
        </div>
        )}
      </div>
    );
  };

  const linkRenderer = ({ children, href }) => {
    if (!isEmpty(children) && typeof children[0] !== 'object') {
      const urlType = children[0].split('=');
      const hrefYoutube = href && href.replace('watch?v=', 'embed/');
      const linkClassName = !isEmpty(urlType) && isEmpty(urlType[1]) ? 'link-default' : '';

      return (
        <div className={`link ${linkClassName}`}>
          {!isEmpty(urlType) && urlType[0] === 'type' && urlType[1] === 'videos'
            ? (
              <iframe
                className="link-video"
                title="ytplayer"
                id="ytplayer"
                type="text/html"
                width="100%"
                height="360"
                src={hrefYoutube}
                frameBorder="0"
              />
            ) : (
              <a className="link-simple" href={href} target="_blank" rel="noreferrer">{children}</a>
            )}
        </div>
      );
    }
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  };

  return (
    <ReactMarkdown
      className={className}
      // eslint-disable-next-line react/no-children-prop
      children={text}
      components={{
        blockquote: quotesRenderer,
        a: linkRenderer,
      }}
    />
  );
};

Markdown.propTypes = {
  text: string,
  className: string,
};

Markdown.defaultProps = {
  text: '',
  className: '',
};

export default Markdown;
