/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const YouTubeIcon = ({
  colorPrimary, width, height, background,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1201.000000, -4370.000000)"
      >
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="4532" />
        <g transform="translate(0.000000, 3803.000000)">
          <rect
            fill={colorPrimary}
            x="0"
            y="390"
            width="1443"
            height="338"
          />
          <g
            transform="translate(1201.000000, 567.000000)"
            stroke={background}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.04"
          >
            <g transform="translate(1.200000, 4.800000)">
              <polygon
                points="10.80012 4.79952 10.80012 15.59952 19.20012 10.19952"
              />
              <path
                d="M3.1536,0.8052 L5.4324,0.5208 C8.208,0.174 11.0028,0 13.8,0 C16.5972,0 19.392,0.174 22.1676,0.5208 L24.4464,0.8052 C26.2488,1.0308 27.6,2.562 27.6,4.3776 L27.6,16.0212 C27.6,17.8368 26.2488,19.368 24.4464,19.5936 L22.1676,19.8792 C19.392,20.226 16.5972,20.4 13.8,20.4 C11.0028,20.4 8.208,20.226 5.4324,19.8792 L3.1536,19.5936 C1.3524,19.368 0,17.8368 0,16.0212 L0,4.3776 C0,2.562 1.3524,1.0308 3.1536,0.8052 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

YouTubeIcon.defaultProps = {
  colorPrimary: '#1D1A1D',
  width: 30,
  height: 30,
  background: '#FFFFFF',
};

YouTubeIcon.propTypes = {
  colorPrimary: string,
  width: number,
  height: number,
  background: string,
};

export default YouTubeIcon;
