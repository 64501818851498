import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import SvgIcon from '../../stories/SvgIcon';

const { Text } = Typography;

const FeaturedNews = ({ relatedNews, navigateToNews }) => {
  const [mainNews] = relatedNews || [];
  const secondaryNews = relatedNews && relatedNews.slice(1, 3);
  const onlyOneNews = secondaryNews && secondaryNews.length === 1 ? 'card-padding' : '';

  const getDate = (data, year) => (year ? moment(data).format('MM.DD.YYYY') : moment(data).format('MM.DD.YY'));

  return (
    <div className="col-lg-12">
      <div className="featured-news-container main-card">

        <div className="col-lg-6 col-sm-12">
          {!isEmpty(mainNews) && !isEmpty(mainNews.data) && (
            <div
              className="main-card-news"
              onClick={navigateToNews(mainNews.data.slug)}
              role="presentation"
            >
              {!isEmpty(mainNews.data.primaryImage)
                && (
                  <div className="main-card-img-primary-wrapper">
                    <img className="main-card-img-primary" src={mainNews.data.primaryImage.url} alt="" />
                  </div>
                )}
              {!isEmpty(mainNews.data.secondaryImage)
                && (
                  <img className="main-card-img-secondary" src={mainNews.data.secondaryImage.url} alt="" />
                )}
              <div className="main-card-content">
                <div className="main-card-head">
                  {!isEmpty(mainNews.data.type) && (
                    <Text className="main-card-category text-white">{mainNews.data.type.label}</Text>
                  )}
                  {!isEmpty(mainNews.data) && !isEmpty(mainNews.data.date) && (
                    <Text className="main-card-date text-white">{getDate(mainNews.data.date, mainNews.data.date)}</Text>
                  )}
                </div>
                <h2 className="main-card-title">{mainNews.data.title}</h2>
                {!isEmpty(mainNews.data.description) && (
                  <Text className="main-card-description text-grey">
                    {mainNews.data.description.slice(0, 101)}
                    {mainNews.data.description.length > 100 ? '...' : ''}
                  </Text>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className={`featured-secondary-news square-card rectangular-card ${onlyOneNews}`}>
            {secondaryNews && secondaryNews.map((oneNews) => (
              !isEmpty(oneNews.data)
                && !isEmpty(oneNews.data.primaryImage)
                && !isEmpty(oneNews.data.secondaryImage)
                ? (
                  <div
                    key={oneNews.data.id}
                    className="square-card-news"
                    onClick={navigateToNews(mainNews.data.slug)}
                    role="presentation"
                  >
                    <div className="square-card-img-main-wrapper">
                      <img
                        className="square-card-img-main"
                        src={oneNews.data.primaryImage.url}
                        alt={oneNews.data.primaryImage.url}
                      />
                    </div>
                    <img
                      className="square-card-img-second"
                      src={oneNews.data.secondaryImage.url}
                      alt={oneNews.data.secondaryImage.url}
                    />
                    <div className="square-card-content">
                      <div className="square-card-head">
                        <div className="square-card-head-icon">
                          {!isEmpty(oneNews.data) && !isEmpty(oneNews.data.type)
                            && !isEmpty(oneNews.data.type.label)
                            && ((oneNews.data.type.label.toLowerCase() === 'videos' && <SvgIcon mode="Play" />)
                              || (oneNews.data.type.label.toLowerCase() === 'podcast' && <SvgIcon mode="Podcast" />)
                            )}
                          {!isEmpty(oneNews.data) && !isEmpty(oneNews.data.type) && (
                            <Text className="square-card-category text-dark">{oneNews.data.type.label}</Text>
                          )}
                        </div>
                        {!isEmpty(oneNews.data) && !isEmpty(oneNews.data.date) && (
                          <Text className="square-card-date text-dark">{getDate(oneNews.data.date)}</Text>
                        )}
                      </div>
                      <h2 className="square-card-title">{oneNews.data.title}</h2>
                      {!isEmpty(oneNews.data.description) && (
                        <Text className="square-card-description text-dark">
                          {oneNews.data.description.slice(0, 101)}
                          {oneNews.data.description.length > 100 ? '...' : ''}
                        </Text>
                      )}
                    </div>
                  </div>
                )
                : (!isEmpty(oneNews.data)
                    && (
                      <div
                        key={oneNews.data.id}
                        className="rectangular-card-news"
                        onClick={navigateToNews(oneNews.data.slug)}
                        role="presentation"
                      >
                        {(!isEmpty(oneNews.data.commonImage)
                          || !isEmpty(oneNews.data.primaryImage)
                          || !isEmpty(oneNews.data.secondaryImage)) && (
                          <div className="rectangular-card-img-wrapper">
                            <img
                              className="rectangular-card-img"
                              src={oneNews.data.commonImage?.url
                                || oneNews.data.primaryImage?.url
                                || oneNews.data.secondaryImage?.url}
                              alt={oneNews.data?.title}
                            />
                          </div>
                        )}
                        <div className="rectangular-card-content">
                          <div className="rectangular-card-head">
                            <div className="square-card-head-icon">
                              {!isEmpty(oneNews.data) && !isEmpty(oneNews.data.type)
                                && !isEmpty(oneNews.data.type.label)
                                && ((oneNews.data.type.label.toLowerCase() === 'videos' && <SvgIcon mode="Play" />)
                                  || (oneNews.data.type.label.toLowerCase() === 'podcast' && <SvgIcon mode="Podcast" />)
                                )}
                              {!isEmpty(oneNews.data) && !isEmpty(oneNews.data.type) && (
                                <Text className="square-card-category text-dark">{oneNews.data.type.label}</Text>
                              )}
                            </div>
                            {!isEmpty(oneNews.data.date) && (
                              <Text className="rectangular-card-date text-dark">{getDate(oneNews.data.date)}</Text>
                            )}
                          </div>
                          <h2 className="rectangular-card-title">{oneNews.data.title}</h2>
                        </div>
                      </div>
                    )
                )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedNews.propTypes = {
  relatedNews: arrayOf(shape()).isRequired,
  navigateToNews: func.isRequired,
};

export default FeaturedNews;
