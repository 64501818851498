import * as React from 'react';
import { Layout as AntdLayout } from 'antd';
import { node, string, shape } from 'prop-types';

import { Helmet } from 'react-helmet';
import HeaderContent from './HeaderContent';
import FooterContent from './FooterContent';
import SignUp from './DynamicComponents/SignUp';

const Layout = ({
  children, subheader, containerClassName, headerData, footerData, signUpData,
}) => {
  const { Header, Footer, Content } = AntdLayout;

  return (
    <AntdLayout className={containerClassName}>
      <Helmet>
        <script>
          {
            `
              EF.click({
                offer_id: EF.urlParameter('oid'),
                affiliate_id: EF.urlParameter('affid') || 1643,
                gclid: EF.urlParameter('gclid'),
                fbclid: EF.urlParameter('fbclid'),
                sub1: EF.urlParameter('utm_content'),
                sub2: EF.urlParameter('utm_term'),
                sub3: EF.urlParameter('utm_campaign')
              });
          `
          }
        </script>
      </Helmet>
      {subheader}
      <Header className="tr-header">
        <HeaderContent data={headerData} />
      </Header>

      <Content>{children}</Content>
      {signUpData && <SignUp content={signUpData} />}

      <Footer className="tr-footer">
        <FooterContent data={footerData} />
      </Footer>
    </AntdLayout>
  );
};

Layout.propTypes = {
  subheader: node,
  children: node.isRequired,
  containerClassName: string,
  headerData: shape().isRequired,
  footerData: shape().isRequired,
  signUpData: shape().isRequired,
};

Layout.defaultProps = {
  subheader: null,
  containerClassName: '',
};

export default Layout;
