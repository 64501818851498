/* eslint-disable max-len */
import React from 'react';
import { number } from 'prop-types';

const ArrowNext = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 12 18" version="1.1">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Join-Menu" transform="translate(-276.000000, -496.000000)" fill="#000000" fillRule="nonzero">
        <path
          d="M278.729167,513.895833 C278.909722,514.076389 279.090278,514.076389 279.270833,513.895833 L279.270833,513.895833 L287.895833,505.270833 C288.076389,505.090278 288.076389,504.909722 287.895833,504.729167 L287.895833,504.729167 L279.270833,496.104167 C279.173611,496.034722 279.083333,496 279,496 C278.916667,496 278.826389,496.034722 278.729167,496.104167 L278.729167,496.104167 L276.104167,498.729167 C275.923611,498.909722 275.923611,499.090278 276.104167,499.270833 L276.104167,499.270833 L281.833333,505 L276.104167,510.729167 C275.923611,510.909722 275.923611,511.090278 276.104167,511.270833 L276.104167,511.270833 L278.729167,513.895833 Z"
          id="NEXT"
        />
      </g>
    </g>
  </svg>
);

ArrowNext.defaultProps = {
  width: 12,
  height: 18,
};

ArrowNext.propTypes = {
  width: number,
  height: number,
};

export default ArrowNext;
