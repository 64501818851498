import React from 'react';
import { Tabs } from 'antd';
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import { isEmpty, uniqBy } from 'lodash';
import Dropdown from '../../stories/Dropdown';

const { TabPane } = Tabs;

const CategoryFilter = ({
  filterItems, changeCategory, handleDropdownCategories, isEvent, eventTitle,
}) => {
  const uniqItems = uniqBy([{ type: { label: 'all' } }, ...filterItems], 'type.label');
  const dropdownItems = uniqBy([{ type: { label: 'all categories' } }, ...filterItems], 'type.label');

  return (
    <div className="category-filter filter-list" id="pagination-anchor">
      <div className="col-lg-5">
        <p className="filter-list-name">{isEvent ? `all ${eventTitle} news` : 'latest'}</p>
      </div>
      <div className="filter-list-container">
        <Tabs defaultActiveKey="1" onChange={changeCategory} type="card">
          {uniqItems.map((tab) => (
            (!isEmpty(tab) && !isEmpty(tab.type)) && (
            <TabPane className="filter-list-item" tab={tab.type.label} key={tab.type.label} />
            )
          ))}
        </Tabs>
        <Dropdown dropdownItems={dropdownItems} changeCategory={handleDropdownCategories} />
      </div>
    </div>
  );
};

CategoryFilter.propTypes = {
  filterItems: arrayOf(shape()).isRequired,
  changeCategory: func.isRequired,
  handleDropdownCategories: func.isRequired,
  isEvent: bool,
  eventTitle: string,
};

CategoryFilter.defaultProps = {
  isEvent: false,
  eventTitle: '',
};

export default CategoryFilter;
