/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';

const Email = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 25 20" version="1.1">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-1118.000000, -4025.000000)" fill={color || '#FFFFFF'} fillRule="nonzero">
        <g transform="translate(0.000000, 3803.000000)">
          <path d="M1130.5,233.462502 L1143,224.087502 C1143,223.220802 1142.3042,222.525002 1141.4375,222.525002 L1119.5625,222.525002 C1118.6958,222.525002 1118,223.220802 1118,224.087502 L1130.5,233.462502 Z M1141.4375,241.275002 C1142.3042,241.275002 1143,240.579201 1143,239.712502 L1143,226.895119 L1130.5,236.270119 L1118,226.895119 L1118,239.712502 C1118,240.579201 1118.6958,241.275002 1119.5625,241.275002 L1141.4375,241.275002 Z" />
        </g>
      </g>
    </g>
  </svg>
);

Email.defaultProps = {
  color: '',
  width: 25,
  height: 20,
};

Email.propTypes = {
  color: string,
  width: string,
  height: string,
};

export default Email;
