import React, { useEffect, useState } from 'react';
import './buttonGroup.scss';
import { string, bool, func } from 'prop-types';
import { isEmpty } from 'lodash';

const ButtonGroup = ({
  labelPrimary, labelSecondary,
  hrefPrimary, hrefSecondary,
  openInNewWindowPrimary, openInNewWindowSecondary,
  onClickPrimary, onClickSecondary, offerIdEverflowPrimary,
  offerIdEverflowSecondary,
}) => {
  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (itemLabel, itemPath, itemOfferIdEverflow, itemClassName, itemOpenInNewWindow) => {
    let path = itemPath;
    if (!isEmpty(itemOfferIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${itemOfferIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${itemOfferIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${itemOfferIdEverflow}&${params}`;
      }
    }

    return (
      <a
        className={itemClassName}
        target={itemOpenInNewWindow && '_blank'}
        href={path}
      >
        {itemLabel.length > 30 ? `${itemLabel.slice(0, 30)}...` : itemLabel}
      </a>
    );
  };
  return (
    <div className="tr-button-group">
      <div
        role="button"
        onKeyDown={null}
        tabIndex={0}
        onClick={onClickPrimary}
      >
        {renderButton(
          labelPrimary,
          hrefPrimary,
          offerIdEverflowPrimary,
          'tr-button tr-button-group-btn tr-button-group-btn-left tr-button-left',
          openInNewWindowPrimary,
        )}
      </div>
      <div
        role="button"
        onKeyDown={null}
        tabIndex={0}
        onClick={onClickSecondary}
      >
        {renderButton(
          labelSecondary,
          hrefSecondary,
          offerIdEverflowSecondary,
          'tr-button tr-button-group-btn tr-button-group-btn-right tr-button-right',
          openInNewWindowSecondary,
        )}
      </div>
    </div>
  );
};

ButtonGroup.propTypes = {
  labelPrimary: string,
  labelSecondary: string,
  offerIdEverflowSecondary: string,
  offerIdEverflowPrimary: string,
  hrefPrimary: string,
  hrefSecondary: string,
  openInNewWindowPrimary: bool,
  openInNewWindowSecondary: bool,
  onClickPrimary: null,
  onClickSecondary: null,
};

ButtonGroup.defaultProps = {
  labelPrimary: '',
  labelSecondary: '',
  offerIdEverflowPrimary: '',
  offerIdEverflowSecondary: '',
  hrefPrimary: '',
  hrefSecondary: '',
  openInNewWindowPrimary: false,
  openInNewWindowSecondary: false,
  onClickPrimary: func,
  onClickSecondary: func,
};

export default ButtonGroup;
