/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const SearchReverse = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-217.000000, -10.000000)">
        <rect fill={color} x="0" y="0" width="320" height="52" />
        <g transform="translate(217.000000, 10.000000)" fill="#FFFFFF">
          <path d="M29.71,28.29 L19.71,18.29 C23.0797972,14.1637032 22.6230335,8.12004821 18.6713466,4.54709006 C14.7196598,0.974131908 8.66068488,1.12651491 4.89359989,4.89359989 C1.12651491,8.66068488 0.974131908,14.7196598 4.54709006,18.6713466 C8.12004821,22.6230335 14.1637032,23.0797972 18.29,19.71 L28.29,29.71 L29.71,28.29 Z M3.99999996,12 C3.99999996,7.58172198 7.58172198,3.99999996 12,3.99999996 C16.418278,3.99999996 20,7.58172198 20,12 C20,16.418278 16.418278,20 12,20 C9.87826806,20 7.84343675,19.1571453 6.34314572,17.6568543 C4.84285469,16.1565632 3.99999996,14.1217319 3.99999996,12 L3.99999996,12 Z" />
        </g>
      </g>
    </g>
  </svg>
);

SearchReverse.defaultProps = {
  color: '#1D1A1D',
  width: 32,
  height: 32,
};

SearchReverse.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default SearchReverse;
