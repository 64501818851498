/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const ExpandSmall = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="tr-change-color" transform="translate(-279.000000, -464.000000)" fill={color} fillRule="nonzero">
        <g transform="translate(15.000000, 410.000000)">
          <polygon transform="translate(272.000000, 58.570185) scale(1, -1) translate(-272.000000, -58.570185) " points="279.113333 54 272.633333 60.6531481 272.008515 61.3238901 271.366667 60.6531481 264.88 54 264 54.9318519 272 63.1403704 280 54.9318519" />
        </g>
      </g>
    </g>
  </svg>
);

ExpandSmall.defaultProps = {
  width: 16,
  height: 9,
  color: '#FFFFFF',
};

ExpandSmall.propTypes = {
  width: number,
  height: number,
  color: string,
};

export default ExpandSmall;
