import React from 'react';
import { Form, Input, Button } from 'antd';
import { func, string } from 'prop-types';
import './field.scss';
import icon from '../images/signUpIcon.svg';

import Email from './assets/icons/Email';

const validateMessages = {
  types: {
    email: 'Enter a valid email address',
  },
};

const Field = ({
  onFinish, buttonLabel, resMsg, onValuesChange, className,
}) => (
  <Form
    className={`tr-form-field ${className}`}
    onFinish={onFinish}
    validateMessages={validateMessages}
    onValuesChange={onValuesChange}
  >
    <Form.Item
      className="error"
      name="email"
    >
      <Input addonAfter="Email Address" placeholder="Email Address" suffix={<img alt="icon" src={icon} />} />
    </Form.Item>

    <div className="message-field ant-form-item-explain ant-form-item-explain-error">
      <div role="alert">{resMsg}</div>
    </div>
    <Form.Item>
      <Button className="tr-button tr-button-sign-up" htmlType="submit">
        <Email width="25px" height="19px" />
        {buttonLabel || 'Sign up'}
      </Button>
    </Form.Item>
  </Form>
);

Field.propTypes = {
  onFinish: func.isRequired,
  onValuesChange: func.isRequired,
  buttonLabel: string.isRequired,
  resMsg: string,
  className: string,
};

Field.defaultProps = {
  resMsg: null,
  className: '',
};

export default Field;
