import React, { useState } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import SvgIcon from './SvgIcon';
import './dropdown.scss';

const { Option } = Select;

const Dropdown = ({ dropdownItems, changeCategory }) => {
  const [selectedValue, setSelectedValue] = useState('All Categories');

  const handleChange = (label) => {
    setSelectedValue(label);
    changeCategory(label);
  };

  return (
    <div className="tr-dropdown-container" id="pagination-anchor">
      <Select
        dropdownClassName="tr-dropdown-menu"
        className="tr-dropdown"
        defaultValue="All Categories"
        value={selectedValue}
        onChange={handleChange}
        suffixIcon={<SvgIcon mode="Triangle" />}
      >
        {!isEmpty(dropdownItems) && dropdownItems.map((item) => (
          !isEmpty(item) && !isEmpty(item.type) && (
            <Option key={item.id} className="tr-dropdown-option">
              {item.type.label}
            </Option>
          )
        ))}
      </Select>
    </div>
  );
};

Dropdown.defaultProps = {
  dropdownItems: [],
  changeCategory: func,
};

Dropdown.propTypes = {
  dropdownItems: arrayOf(shape()),
  changeCategory: func,
};

export default Dropdown;
