import React from 'react';
import { navigate } from 'gatsby';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';
import Marquee from 'react-fast-marquee';

import Container from '../Container';
import Button from '../../stories/Button';
import { marqueeSpeed } from '../../utils/constants';

const NotFound = ({ content }) => {
  const {
    backgroundText, button, description, title,
  } = content;
  const redirectHome = (path) => () => {
    navigate(path);
  };

  return (
    <div className="not-found-container">
      {!isEmpty(backgroundText) && (
        <>
          <span className="not-found-container-marquee-line" />
          <Marquee speed={marqueeSpeed}>
            <p className="not-found-container-background-text">
              {(` ${backgroundText} `).repeat(30)}
            </p>
          </Marquee>
        </>
      )}
      <Container>
        <div className="col-lg-offset-3 col-lg-7 col-md-offset-2 col-md-8">
          <div className="not-found-content">
            <h1 className="not-found-title">{title}</h1>
            <p className="not-found-description">{description}</p>
            {!isEmpty(button) && !isEmpty(button.data) && !isEmpty(button.data.path) && (
              <Button
                mode="filled-dark"
                onClick={(redirectHome(button.data.path))}
                label={button.label || button.data.label}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

NotFound.propTypes = {
  content: shape.isRequired,
};

export default NotFound;
