import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { List } from 'antd';
import { isEmpty } from 'lodash';
import { shape } from 'prop-types';
import Container from '../Container';

const FooterContent = ({ data }) => {
  const navigateHome = () => {
    navigate('/');
  };

  const leftCol = [];
  const rightCol = [];
  const isOdd = (num) => num % 2;

  if (!isEmpty(data.links)) {
    for (let i = 0; i < data.links.length; i += 1) {
      if (isOdd(i)) {
        leftCol.push(data.links[i]);
      } else rightCol.push(data.links[i]);
    }
  }

  const links = [...leftCol, ...rightCol];

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (everlowClick, item) => {
    let path = item.data?.path || item?.path;

    if (!isEmpty(item.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${item.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${item.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${item.offerIdEverflow}&${params}`;
      }
    }

    return (
      <div role="button" onClick={everlowClick} onKeyDown={everlowClick} tabIndex={0}>
        <a
          className="tr-footer-list-link"
          target={(item.data?.openInNewWindow || item.openInNewWindow) && '_blank'}
          href={path}
        >
          {item.label || item.data?.label}
        </a>
      </div>
    );
  };

  return (
    <Container>
      {data
        && (
        <div className="tr-footer-wrapper">
          <div className="tr-footer-logo-wrapper">
            <div role="presentation" onClick={navigateHome} className="tr-footer-logo">
              <img
                className="tr-footer-logo-img"
                src={data.logo?.url || ''}
                alt="footer logo"
              />
            </div>
            <span className="tr-footer-helper" />
          </div>

          {!isEmpty(links)
            && (
              <div className="tr-footer-list tr-footer-list-text">
                <List
                  header={<div>{data.linksTitle}</div>}
                  dataSource={links}
                  renderItem={(item) => {
                    const everlowClick = async () => {
                      await window.EF.click({
                        offer_id: item.offerIdEverflow,
                        affiliate_id: window.EF.urlParameter('affid') || 1643,
                        gclid: window.EF.urlParameter('gclid'),
                        fbclid: window.EF.urlParameter('fbclid'),
                        sub1: window.EF.urlParameter('utm_content'),
                        sub2: window.EF.urlParameter('utm_term'),
                        sub3: window.EF.urlParameter('utm_campaign'),
                      });

                      return null;
                    };

                    return (
                      !isEmpty(item) && (!isEmpty(item.label) || !isEmpty(item.data?.label))
                      && (!isEmpty(item.data?.path) || !isEmpty(item.path))
                      && (
                        <List.Item key={item.id}>
                          {renderButton(everlowClick, item)}
                        </List.Item>
                      ));
                  }}
                />
              </div>
            )}

          {!isEmpty(data.socialLinks)
            && (
              <div className="tr-footer-list tr-footer-list-social-icons">
                <List
                  header={<div>{data.socialLinksTitle}</div>}
                  dataSource={data.socialLinks}
                  renderItem={(item) => (
                    !isEmpty(item) && !isEmpty(item.socialLink)
                      && (
                        <List.Item key={item.id}>
                          <a
                            className="tr-footer-list-link"
                            href={item.socialLink?.path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="main-image"
                              src={item.socialLink?.logo?.url || ''}
                              alt={item.label || item?.data?.label}
                            />
                            <img
                              className="hover-image"
                              src={item.socialLink?.hoverImage?.url || ''}
                              alt={item.label || item?.data?.label}
                            />
                          </a>
                        </List.Item>
                      )
                  )}
                />
              </div>
            )}
        </div>
        )}
    </Container>
  );
};

FooterContent.propTypes = {
  data: shape().isRequired,
};

export default FooterContent;
