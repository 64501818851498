/* eslint-disable max-len */
import React from 'react';
import { number } from 'prop-types';

const Expand = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(-486.000000, -352.000000)">
        <g transform="translate(486.000000, 352.000000)">
          <circle
            stroke="#FFFFFF"
            fill="#1D1A1D"
            cx="16"
            cy="16"
            r="15.5"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            transform="translate(16.000000, 15.570185) scale(1, -1) translate(-16.000000, -15.570185) "
            points="23.1133333 11 16.6333333 17.6531481 16.0085146 18.3238901 15.3666667 17.6531481 8.88 11 8 11.9318519 16 20.1403704 24 11.9318519"
          />
        </g>
      </g>
    </g>
  </svg>
);

Expand.defaultProps = {
  width: 32,
  height: 32,
};

Expand.propTypes = {
  width: number,
  height: number,
};

export default Expand;
