/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const Podcast = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 34 34" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="UI/Symbols" transform="translate(-233.000000, -524.000000)">
        <g id="Group" transform="translate(233.000000, 524.000000)">
          <g id="Oval-2" fill="#ED1849">
            <circle fill={color} id="Oval" cx="17" cy="17" r="17" />
          </g>
          <path d="M17,24.5 L21.375,28 L12.625,28 L17,24.5 Z M22.25,14.875 L22.25,16.625 C22.25,19.520375 19.895375,21.875 17,21.875 C14.104625,21.875 11.75,19.520375 11.75,16.625 L11.75,14.875 L10,14.875 L10,16.625 C10,20.49075 13.13425,23.625 17,23.625 C20.86575,23.625 24,20.49075 24,16.625 L24,14.875 L22.25,14.875 Z M13.5,16.625 L13.5,10.5 C13.5,8.567125 15.067125,7 17,7 C18.932875,7 20.5,8.567125 20.5,10.5 L20.5,16.625 C20.5,18.557875 18.932875,20.125 17,20.125 C15.067125,20.125 13.5,18.557875 13.5,16.625 Z M15.25,10.5 L15.25,14 L18.75,14 L18.75,10.5 C18.75,9.534875 17.966,8.75 17,8.75 C16.034875,8.75 15.25,9.534875 15.25,10.5 Z" id="Shape" fill="#FFFFFF" />
        </g>
      </g>
    </g>
  </svg>
);

Podcast.defaultProps = {
  color: '#ED1849',
  width: 34,
  height: 34,
};

Podcast.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default Podcast;
