import React from 'react';
import {
  string, oneOfType, node, bool, number,
} from 'prop-types';

const renderChildren = (useInnerContainer, children, innerContainerClassName) => (
  useInnerContainer
    ? <div className={`container ${innerContainerClassName}`}>{children}</div>
    : children
);

const Container = (props) => {
  const {
    className, children, fluid, size, gap, spacing, useInnerContainer,
    innerContainerClassName,
  } = props;
  const fluidClassName = fluid ? 'container-fluid' : '';
  const spacinglassName = spacing ? `container-spacing-${spacing}` : '';
  const sizeClassName = size ? `container-${size}` : '';
  const gaplassName = gap ? `container-gap-${gap}` : '';
  return (
    <div
      id={props.id}
      className={`container ${className} ${fluidClassName} ${sizeClassName} ${gaplassName} ${spacinglassName}`}
    >
      {renderChildren(useInnerContainer, children, innerContainerClassName)}
    </div>
  );
};

Container.propTypes = {
  id: string,
  className: string,
  innerContainerClassName: string,
  size: string,
  spacing: oneOfType([number, string]),
  gap: oneOfType([node, string]),
  children: oneOfType([node, string]),
  fluid: bool,
  useInnerContainer: bool,
};

Container.defaultProps = {
  id: '',
  className: '',
  innerContainerClassName: '',
  spacing: '',
  size: 'default',
  gap: '0',
  fluid: false,
  useInnerContainer: false,
  children: null,
};
export default Container;
