/* eslint-disable max-len */
import React from 'react';
import { string, number } from 'prop-types';

const BurgerButton = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-273.000000, -10.000000)">
        <rect fill="#1D1A1D" x="0" y="0" width="320" height="52" />
        <g transform="translate(273.000000, 10.000000)" fill={color}>
          <g transform="translate(3.000000, 6.000000)">
            <path d="M26,18 L26,20 L0,20 L0,18 L26,18 Z M26,9.00000002 L26,11 L0,11 L0,9.00000002 L26,9.00000002 Z M26,8.8817842e-16 L26,2 L0,2 L0,8.8817842e-16 L26,8.8817842e-16 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BurgerButton.defaultProps = {
  color: '#FFFFFF',
  width: 32,
  height: 32,
};

BurgerButton.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default BurgerButton;
