import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash';
import { Typography } from 'antd';
import moment from 'moment';

import RichText from '../../elements/RichText';
import Container from '../../Container';

const { Text } = Typography;

const NewsContent = ({ content, data }) => {
  const {
    author, button, richText, label, title,
  } = content;

  const {
    backgroundText, relatedEvents,
    relatedEventsButton, socialLinks, pageContext, type,
    mobileEventBanner, mediaContentUrl,
  } = data;

  const redirect = (path) => () => {
    navigate(path);
  };

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const height = document.querySelector('.article-news-container')?.offsetHeight || 0;
    setContentHeight(height);
  }, []);

  const getDate = (date, long) => (long ? moment(date).format('MM.DD.YYYY') : moment(date).format('MM.DD.YY'));
  const withoutText = backgroundText ? '' : 'mt';
  const hasRelatedEvents = relatedEvents && relatedEvents.some((e) => !isEmpty(e.data));

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (item) => {
    let path = item.data?.path || item?.path;
    if (!isEmpty(item.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${item.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${item.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${item.offerIdEverflow}&${params}`;
      }
    }

    return (
      <a
        href={path}
        target={(item.data?.openInNewWindow || item.openInNewWindow) && '_blank'}
        className="tr-button tr-button-filled-dark"
      >
        {item.label || item.data?.label || ''}
      </a>
    );
  };

  return (
    <div className="article-news-container sidebar">
      <Container>
        {!isEmpty(backgroundText) && (
          <p
            className="article-news-background-text"
            style={{ width: contentHeight * 2 }}
          >
            {(` ${backgroundText} `).repeat(30)}
          </p>
        )}
        <div className={`article-news-content ${withoutText}`}>
          <div className="col-lg-1">
            <div className="article-news-img-container">
              {socialLinks && socialLinks.map((icon) => (
                !isEmpty(icon.socialLink)
                  && !isEmpty(icon.socialLink.logo)
                  && !isEmpty(icon.socialLink.path)
                  && (
                    <a
                      href={icon.socialLink.path}
                      className="article-news-img-wrapper"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="article-news-img"
                        key={icon.socialLink.id}
                        src={icon.socialLink.logo.url}
                        alt={icon.socialLink.path}
                      />
                      {!isEmpty(icon.socialLink.hoverImage)
                        && icon.socialLink.hoverImage.url
                        && (
                          <img
                            className="article-news-img-hover"
                            key={icon.socialLink.id}
                            src={icon.socialLink.hoverImage.url}
                            alt={icon.socialLink.path}
                          />
                        )}
                    </a>
                  )
              ))}
            </div>
          </div>

          <div className="col-lg-8 col-md-8">
            <div className="section-news-content article">
              {!isEmpty(label) && (
                <p className="article-label">{label}</p>
              )}
              {!isEmpty(title) && (
                <h1 className="article-title">{title}</h1>
              )}
              {!isEmpty(mediaContentUrl)
                && !isEmpty(type)
                && !isEmpty(type.label)
                && (type.label.toLowerCase() === 'videos')
                && (
                  <iframe
                    className="article-news-video"
                    title="ytplayer"
                    id="ytplayer"
                    type="text/html"
                    width="100%"
                    height="360"
                    src={mediaContentUrl.replace('watch?v=', 'embed/')}
                    frameBorder="0"
                  />
                )}
              {!isEmpty(mediaContentUrl)
                && !isEmpty(type)
                && !isEmpty(type.label)
                && (type.label.toLowerCase() === 'podcast')
                && (
                  <iframe
                    className="article-news-podcast"
                    title="apple-podcast"
                    src={mediaContentUrl}
                    height="175px"
                    frameBorder="0"
                    allow="autoplay *; encrypted-media *;"
                  />
                )}
              {!isEmpty(richText) && (
                <RichText text={richText} />
              )}
              <div className="article-button">
                {((!isEmpty(button)
                  && !isEmpty(button.data)
                  && !isEmpty(button.data.path))
                  || (!isEmpty(button)
                      && !isEmpty(button.label) && !isEmpty(button.path)))
                  && (
                    <div
                      role="button"
                      onKeyDown={null}
                      tabIndex={0}
                      onClick={async () => {
                        await window.EF.click({
                          offer_id: button.offerIdEverflow,
                          affiliate_id: window.EF.urlParameter('affid') || 1643,
                          gclid: window.EF.urlParameter('gclid'),
                          fbclid: window.EF.urlParameter('fbclid'),
                          sub1: window.EF.urlParameter('utm_content'),
                          sub2: window.EF.urlParameter('utm_term'),
                          sub3: window.EF.urlParameter('utm_campaign'),
                        });
                      }}
                    >
                      {renderButton(button)}
                    </div>
                  )}
              </div>
              <div className="article-info">
                {!isEmpty(author) && (
                  <p className="article-info-author">{author}</p>
                )}
                <p className="article-info-date">{getDate(data.date, data.date)}</p>
              </div>
            </div>
            <div className="article-news-icon-container">
              <div className="article-news-icon-block">
                {socialLinks && socialLinks.map((icon) => (
                  !isEmpty(icon.socialLink)
                    && !isEmpty(icon.socialLink.logo)
                    && !isEmpty(icon.socialLink.path)
                    && (
                      <a
                        href={icon.socialLink.path}
                        className="article-news-icon-wrapper"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="article-news-icon"
                          key={icon.socialLink.id}
                          src={icon.socialLink.logo.url}
                          alt={icon.socialLink.path}
                        />
                        {!isEmpty(icon.socialLink.hoverImage)
                          && icon.socialLink.hoverImage.url
                          && (
                          <img
                            className="article-news-icon-hover"
                            key={icon.socialLink.id}
                            src={icon.socialLink.hoverImage.url}
                            alt={icon.socialLink.path}
                          />
                          )}
                      </a>
                    )
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 pr">
            <div className="sidebar-container">
              {hasRelatedEvents && (
              <>
                <div className="sidebar-events">
                  <p className="sidebar-events-name">events</p>
                  <div className="sidebar-events-block">
                    {relatedEvents.slice(0, 2).map((event) => {
                      const secondDate = !isEmpty(event.data?.dateDayOfWeek)
                      && event.data.dateDayOfWeek.length <= 2 ? '-second' : '';

                      return !isEmpty(event.data) && (
                      <div
                        className={`sidebar-event-container ${event.data.slug ? 'link' : ''}`}
                        key={event.data.id}
                        role="presentation"
                        onClick={redirect(event.data.slug)}
                      >
                        {!isEmpty(event.data.image) && !isEmpty(event.data.image.url) && (
                        <img className="sidebar-event-img" src={event.data.image.url} alt={event.data.image.url} />
                        )}
                        <div className="sidebar-event-info">
                          <div className="sidebar-event-info-content">
                            <Text className="sidebar-event-info-month">{event.data.dateMonth?.slice(0, 3)}</Text>
                            <Text className={`sidebar-event-info-date${secondDate}`}>{event.data.date}</Text>
                            <Text className="sidebar-event-info-time">{event.data.dateClock}</Text>
                          </div>
                          <div className="sidebar-event-content">
                            <h2 className="sidebar-event-content-title">{event.data.title}</h2>
                            {!isEmpty(event.data.description) && (
                            <Text className="sidebar-event-content-description">
                              {event.data.description.slice(0, 40)}
                              {event.data.description.length < 40 ? '' : '...'}
                            </Text>
                            )}
                          </div>
                        </div>
                      </div>
                      );
                    })}
                  </div>
                  {!isEmpty(relatedEventsButton)
                  && ((!isEmpty(relatedEventsButton.data)
                  && !isEmpty(relatedEventsButton.data.path)
                  && !isEmpty(relatedEventsButton.data.label))
                  || (!isEmpty(relatedEventsButton.label)
                  && !isEmpty(relatedEventsButton.path)))
                  && (
                    <div className="sidebar-event-button tr-button">
                      <a
                        href={relatedEventsButton.data?.path || relatedEventsButton.path}
                        target={(relatedEventsButton.data?.openInNewWindow
                          || relatedEventsButton.openInNewWindow)
                          && '_blank'}
                        className="tr-button tr-button-filled-dark"
                      >
                        {relatedEventsButton.label || relatedEventsButton.data.label || ''}
                      </a>
                    </div>
                  )}

                  {mobileEventBanner && !isEmpty(mobileEventBanner.data) && (
                    <div className="sidebar-mobile-event">
                      {!isEmpty(mobileEventBanner.data.event.data)
                        && !isEmpty(mobileEventBanner.data.event.data.image)
                        && (
                          <img
                            className="sidebar-mobile-event-image"
                            src={mobileEventBanner.data.event.data.image.url}
                            alt={mobileEventBanner.data.event.data.title}
                          />
                        )}
                      <div
                        className="sidebar-mobile-event-container"
                        style={{
                          backgroundImage: mobileEventBanner
                            && `url(${mobileEventBanner.mobileBackground?.url})`,
                        }}
                      >
                        <div className="sidebar-mobile-event-head">
                          <Text className="sidebar-mobile-event-day-week">
                            {mobileEventBanner.data.event.data.dateDayOfWeek}
                          </Text>
                          <Text className="sidebar-mobile-event-month">
                            {mobileEventBanner.data.event.data.dateMonth}
                          </Text>
                          <Text className="sidebar-mobile-event-day">
                            {mobileEventBanner.data.event.data.date}
                          </Text>
                          <Text className="sidebar-mobile-event-time">
                            {mobileEventBanner.data.event.data.dateClock}
                          </Text>
                          <Text className="sidebar-mobile-event-time-zone">
                            {mobileEventBanner.data.event.data.dateTimezone}
                          </Text>
                        </div>
                        <h2 className="sidebar-mobile-event-title">
                          {mobileEventBanner.data.event.label || mobileEventBanner.data.event.date.title}
                        </h2>
                        <div className="sidebar-mobile-event-buttons">
                          {!isEmpty(mobileEventBanner)
                            && !isEmpty(mobileEventBanner.data)
                            && !isEmpty(mobileEventBanner.data.bannerLinks)
                            && mobileEventBanner.data.bannerLinks.map((eventButton) => (
                              !isEmpty(eventButton)
                                && ((!isEmpty(eventButton.data)
                                && !isEmpty(eventButton.data.path)
                                && !isEmpty(eventButton.data.label))
                                || (!isEmpty(eventButton.path)
                                && !isEmpty(eventButton.label)))
                                && (
                                  <a
                                    href={eventButton.data?.path || eventButton.path}
                                    target={(eventButton.data?.openInNewWindow || eventButton.openInNewWindow)
                                      && '_blank'}
                                    className="tr-button tr-button-filled-dark"
                                  >
                                    {eventButton.label || eventButton.data.label || ''}
                                  </a>
                                )
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <span className="sidebar-events-border" />
              </>
              )}

              <div className="sidebar-news">
                <p className="sidebar-news-name">latest news</p>
                {!isEmpty(pageContext?.latestNews) && pageContext.latestNews.map((oneNews, index) => (
                  index === 0
                    ? (
                      <div
                        className="sidebar-news-main"
                        key={oneNews.id}
                        role="presentation"
                        onClick={redirect(oneNews.slug)}
                      >
                        {(!isEmpty(oneNews.commonImage)
                          || !isEmpty(oneNews.primaryImage)
                          || !isEmpty(oneNews.secondaryImage))
                          && (
                          <img
                            className="sidebar-news-main-img"
                            src={oneNews.commonImage?.url || oneNews.primaryImage?.url || oneNews.secondaryImage?.url}
                            alt={oneNews.slug}
                          />
                          )}
                        <div className="sidebar-news-main-head">
                          {!isEmpty(oneNews.type) && (
                            <Text className="sidebar-news-main-category">{oneNews.type.label}</Text>
                          )}
                          <Text className="sidebar-news-main-date">
                            {!isEmpty(oneNews.date) && getDate(oneNews.date)}
                          </Text>
                        </div>
                        <h2 className="sidebar-news-main-title">{oneNews.title}</h2>
                      </div>
                    )
                    : (
                      <div
                        className="sidebar-news-second"
                        key={oneNews.id}
                        role="presentation"
                        onClick={redirect(oneNews.slug)}
                      >
                        <h2 className="sidebar-news-second-title">{oneNews.title}</h2>
                        <Text className="sidebar-news-second-date">{getDate(oneNews.date)}</Text>
                      </div>
                    )
                ))}
                <div className="sidebar-news-button tr-button">
                  <a
                    href="/news"
                    className="tr-button tr-button-filled-dark"
                  >
                    All News
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

NewsContent.propTypes = {
  content: shape().isRequired,
  data: shape().isRequired,
};

export default NewsContent;
