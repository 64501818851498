import React from 'react';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';

import RichText from '../../elements/RichText';
import Container from '../../Container';

const DynamicPageContent = ({ content }) => {
  const { backgroundText = '' } = content;
  const image = !isEmpty(content.image) && content.image?.url;
  const richText = content?.text;
  const getBackgroundText = () => (
    !isEmpty(backgroundText) && <p className="dynamic-page-background-text">{(`${backgroundText} `).repeat(30)}</p>
  );

  return (
    <div className="dynamic-page">
      <Container>
        {getBackgroundText()}
        <div className="dynamic-page-content">
          {!isEmpty(image) && <img className="dynamic-page-main-image" src={image} alt={image} />}
          {richText && <RichText text={richText} />}
        </div>
        {getBackgroundText()}
      </Container>
    </div>
  );
};

DynamicPageContent.propTypes = {
  content: shape(),
};

DynamicPageContent.defaultProps = {
  content: {},
};

export default DynamicPageContent;
