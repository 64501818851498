import React from 'react';
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import { Typography } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';

import SvgIcon from '../../stories/SvgIcon';
import EventBanner from '../DynamicComponents/BannerWithOneEvent';

const { Text } = Typography;

const NewsList = ({
  news, navigateToNews, button, cardClass, banners, isEvent,
}) => {
  const connectNewsWithBanner = (newsToSort, bannersToSort) => {
    const newsWithIndexes = newsToSort && newsToSort.map((n, index) => ({
      ...n,
      index,
      contentType: 'news',
    }));
    const bannersWithIndexes = bannersToSort && bannersToSort.map((b) => ({
      ...b,
      index: b.afterNewsIndex - 1,
      contentType: 'banner',
    }));

    const newsWithBanners = newsWithIndexes
      .concat(bannersWithIndexes)
      .sort((a, b) => {
        if (a.index < b.index) return -1;
        if (a.index >= b.index) return 1;
        return 0;
      });

    return newsWithBanners;
  };

  let sortedNews = news;

  if (!isEmpty(banners)) {
    sortedNews = connectNewsWithBanner(sortedNews, banners);
  } else {
    sortedNews = sortedNews?.map((sN) => ({
      ...sN,
      contentType: 'news',
    }));
  }

  sortedNews = uniqBy(sortedNews, 'id');

  const getDate = (date) => moment(date).format('MM.DD.YYYY');

  return (
    <div className="col-lg-offset-1 col-lg-10 col-sm-12 news-event-banner">
      {sortedNews && sortedNews.map((oneNews, index) => (
        ((oneNews.contentType === 'news'
        && (oneNews.isFeatured ? (
          <div
            key={oneNews.id}
            className="news-list-featured-news featured-news"
            onClick={navigateToNews(oneNews.slug)}
            role="presentation"
          >
            <div className="featured-news-img-container">
              {(!isEmpty(oneNews.commonImage)
                || !isEmpty(oneNews.primaryImage)
                || !isEmpty(oneNews.secondaryImage))
                && (
                <img
                  className={`featured-news-img ${cardClass}`}
                  src={oneNews.commonImage?.url || oneNews.primaryImage?.url || oneNews.secondaryImage?.url}
                  alt={oneNews.commonImage?.url || oneNews.primaryImage?.url || oneNews.secondaryImage?.url}
                />
                )}
              <div className="featured-news-icon">
                {!isEmpty(oneNews.type)
                  && !isEmpty(oneNews.type.label)
                  && ((oneNews.type.label.toLowerCase() === 'videos' && <SvgIcon mode="Play" />)
                  || (oneNews.type.label.toLowerCase() === 'podcast' && (<SvgIcon mode="Podcast" />))
                  )}
              </div>
            </div>
            <div className="featured-news-content">
              <div className={`featured-news-content-border ${isEvent && 'event-news'}`}>
                <div className={`featured-news-head ${isEvent && 'event-news'}`}>
                  {!isEmpty(oneNews.type) && (
                    <Text className="featured-news-category text-dark">{oneNews.type.label}</Text>
                  )}
                  {!isEmpty(oneNews.date) && (
                    <Text className="featured-news-date text-dark">{getDate(oneNews.date)}</Text>
                  )}
                </div>
                <h2 className={`featured-news-title ${isEvent && 'event-news'}`}>{oneNews.title}</h2>
                {isEvent && !isEmpty(oneNews.date) && (
                  <Text className={`featured-news-date text-dark ${isEvent && 'event-news'}`}>
                    {getDate(oneNews.date)}
                  </Text>
                )}
                {!isEmpty(oneNews.description) && (
                  <Text className="featured-news-description text-dark">
                    {oneNews.description.slice(0, 101)}
                    {oneNews.description.length > 100 ? '...' : ''}
                  </Text>
                )}
              </div>
            </div>
          </div>
        )
          : oneNews && (!isEmpty(oneNews.commonImage) || !isEmpty(oneNews.primaryImage) || !isEmpty(oneNews.secondary))
            && !isEmpty(oneNews.description) && !isEmpty(oneNews.title) && (
            <div
              key={oneNews.id}
              className="news-list-simple-news simple-news"
              onClick={navigateToNews(oneNews.slug)}
              role="presentation"
            >
              <div className="simple-news-img-wrapper">
                <img
                  className="simple-news-img"
                  src={oneNews.commonImage?.url || oneNews.primaryImage?.url || oneNews.secondary?.url}
                  alt={oneNews.commonImage?.url || oneNews.primaryImage?.url || oneNews.secondary?.url}
                />
              </div>
              <div className={`simple-news-content ${isEvent && 'event-news'}`}>
                <div className={`simple-news-head ${isEvent && 'event-news'}`}>
                  <div className="simple-news-icon">
                    {!isEmpty(oneNews.type)
                      && !isEmpty(oneNews.type.label)
                      && ((oneNews.type.label.toLowerCase() === 'videos' && <SvgIcon mode="Play" />)
                        || (oneNews.type.label.toLowerCase() === 'podcast' && <SvgIcon mode="Podcast" />)
                      )}
                    {!isEmpty(oneNews.type) && (
                      <Text className="simple-news-category text-dark">{oneNews.type.label}</Text>
                    )}
                  </div>
                  {!isEmpty(oneNews.date) && (
                    <Text className="simple-news-date text-dark">{getDate(oneNews.date)}</Text>
                  )}
                </div>
                <h2 className={`simple-news-title ${isEvent && 'event-news'}`}>{oneNews.title}</h2>
                {isEvent && !isEmpty(oneNews.date) && (
                  <Text className={`simple-news-date text-dark ${isEvent && 'event-news'}`}>
                    {getDate(oneNews.date)}
                  </Text>
                )}
              </div>
            </div>
          )))
          || (oneNews.contentType === 'banner'
          && (
            <EventBanner content={oneNews} isLast={(sortedNews.length - 1) === index} />
          ))
        )
      ))}
      {((!isEmpty(button) && !isEmpty(button.data) && !isEmpty(button.data.path))
        || (!isEmpty(button) && !isEmpty(button.label) && !isEmpty(button.path)))
        && (
        <div className="news-list-button">
          <a
            href={button.data?.path || button.path || ''}
            target={(button.data?.openInNewWindow || button.openInNewWindow) && '_blank'}
            className="tr-button tr-button-filled-light"
          >
            {button.label || button.data?.label}
          </a>
        </div>
        )}
    </div>
  );
};

NewsList.propTypes = {
  news: arrayOf(shape()).isRequired,
  navigateToNews: func.isRequired,
  button: shape(),
  cardClass: string,
  banners: arrayOf(shape()),
  isEvent: bool,
};

NewsList.defaultProps = {
  button: null,
  banners: null,
  cardClass: '',
  isEvent: false,
};

export default NewsList;
