import React from 'react';
import { shape, string } from 'prop-types';
import Markdown from '../Markdown';

const RichText = ({ data, text }) => (
  <div className="rich-text-container">
    <Markdown
      className="rich-text"
      text={data.text || text}
    />
  </div>
);

RichText.propTypes = {
  data: shape({
    text: string,
  }),
  text: string,
};

RichText.defaultProps = {
  data: {},
  text: '',
};

export default RichText;
