/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const Quotes = ({
  color, width, height, className,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 23 17" version="1.1">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Article-v2" transform="translate(-251.000000, -1610.000000)" fill={color} fillRule="nonzero">
        <g id="Group-11" transform="translate(251.226300, 1556.000000)">
          <path d="M22.4802004,70.5336996 L22.4802004,61.4655994 L18.5163003,61.4655994 C18.7878003,58.9134993 19.5480004,56.1984993 20.3625004,54.0807993 L14.6610003,54.0807993 C14.0637003,55.3839993 12.5976002,59.2935994 12.5976002,63.5832994 L12.5976002,70.5336996 L22.4802004,70.5336996 Z M9.88260018,70.5336996 L9.88260018,61.4655994 L5.91870011,61.4655994 C6.19020011,58.9134993 6.95040013,56.1984993 7.76490014,54.0807993 L2.06340004,54.0807993 C1.46610003,55.3839993 0,59.2935994 0,63.5832994 L0,70.5336996 L9.88260018,70.5336996 Z" id="“" />
        </g>
      </g>
    </g>
  </svg>
);

Quotes.defaultProps = {
  color: '#ED1849',
  width: 22,
  height: 16,
  className: '',
};

Quotes.propTypes = {
  color: string,
  className: string,
  width: number,
  height: number,
};

export default Quotes;
