import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { navigate, Link } from 'gatsby';

import EventsList from '../../EventsList/EventsList';
import Container from '../../Container';

const { Text } = Typography;

const HeroEventSecond = ({ content }) => {
  const { bannerLinks, relatedEvent } = content;
  const { data } = relatedEvent || {};

  const navigateToEvent = (path) => () => {
    navigate(path);
  };

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (button) => {
    let path = button.data?.path || button?.path;
    if (!isEmpty(button.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${button.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${button.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${button.offerIdEverflow}&${params}`;
      }
    }

    return (
      <Link
        className="tr-button tr-button-filled-dark"
        to={path}
        target={(button.data?.openInNewWindow || button.openInNewWindow) && '_blank'}
      >
        {button.label || button.data.label}
      </Link>
    );
  };

  return (
    <Container id="hero-news-container">
      <div className="hero-event-second event-second">
        {!isEmpty(data)
          && !isEmpty(data.image)
          && (
            <div
              className="event-second-img-container "
              onClick={navigateToEvent(data.slug)}
              role="presentation"
            >
              <img className="event-second-img" src={data.image.url} alt={data.image.url} />
            </div>
          )}
        <div className="event-second-content">
          {!isEmpty(data) && (
            <div className="event-second-info-container">
              <p className="event-second-info-month">{`${data.dateMonth?.slice(0, 3)} ${data.date}`}</p>
              <p className="event-second-info-date">{data.dateDayOfWeek}</p>
              <div className="event-second-info-head">
                <Text className="event-second-info-time text-dark">{data.dateClock}</Text>
                <p className="event-second-info-timezone">{data.dateTimezone}</p>
              </div>
            </div>
          )}
          {!isEmpty(bannerLinks) && bannerLinks.map((button) => (
            ((!isEmpty(button) && !isEmpty(button.data) && !isEmpty(button.data.path))
              || (!isEmpty(button) && !isEmpty(button.label) && !isEmpty(button.path)))
            && (
            <div
              key={button.data?.id}
              className="event-second-button tr-button"
              role="button"
              onKeyDown={null}
              tabIndex={0}
              onClick={async () => {
                await window.EF.click({
                  offer_id: button.offerIdEverflow,
                  affiliate_id: window.EF.urlParameter('affid') || 1643,
                  gclid: window.EF.urlParameter('gclid'),
                  fbclid: window.EF.urlParameter('fbclid'),
                  sub1: window.EF.urlParameter('utm_content'),
                  sub2: window.EF.urlParameter('utm_term'),
                  sub3: window.EF.urlParameter('utm_campaign'),
                });
              }}
            >
              {renderButton(button)}
            </div>
            )
          ))}
        </div>
      </div>
      {!isEmpty(relatedEvent)
        && !isEmpty(relatedEvent.data)
        && !isEmpty(relatedEvent.data.additionalNews)
        && (
          <EventsList
            events={relatedEvent.data.additionalNews}
            newsBackgroundImage={content.newsBackgroundImage}
            button={content.button}
            navigateToEvent={navigateToEvent}
          />
        )}
    </Container>
  );
};

HeroEventSecond.propTypes = {
  content: shape().isRequired,
};

export default HeroEventSecond;
