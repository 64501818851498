import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';

import { Collapse } from 'antd';
import Container from '../../Container';
import SvgIcon from '../../../stories/SvgIcon';
import { marqueeSpeed } from '../../../utils/constants';

const FightCard = (props) => {
  const { content } = props;

  const { Panel } = Collapse;

  const [openPanels, setOpenPanels] = useState([]);
  const [openArrows, setOpenArrows] = useState([]);

  const toggleAll = (arr, index) => () => {
    if (openPanels.some((el) => arr?.includes(el))) {
      const res = openPanels.filter((el) => arr.indexOf(el) < 0);
      setOpenPanels(res);
      const arrowsRes = [...openArrows];
      const filteredRes = arrowsRes.filter((item) => item !== index);
      setOpenArrows(filteredRes);
    } else {
      setOpenPanels([...openPanels, ...arr]);
      const arrowsRes = [...openArrows, index];
      setOpenArrows(arrowsRes);
    }
  };

  const renderHeader = (image, imageUrl, firstName, secondName, stats) => (
    <>
      <div className="tr-fight-table-fighter-image">
        {!isEmpty(image)
          && (
            <img
              src={imageUrl}
              alt={firstName}
            />
          )}
      </div>
      <div className="tr-fight-table-fighter-info">
        <div className="tr-fight-table-fighter-info-firstname">
          {!isEmpty(firstName) && firstName}
        </div>
        <div className="tr-fight-table-fighter-info-secondname">
          {!isEmpty(secondName) && secondName}
        </div>
        <div className="tr-fight-table-fighter-info-record">
          {!isEmpty(stats) && stats}
        </div>
      </div>
    </>
  );

  const renderTable = (height, reach, weight, age, location) => (
    <table className="tr-fight-data">
      <tbody className="tr-fight-data-body">
        <tr>
          <td className="tr-fight-data-header">
            <div className="tr-fight-data-col">
              Height
              <td className="tr-fight-data-primary">{height || ''}</td>
            </div>
          </td>
          <td className="tr-fight-data-header">
            <div className="tr-fight-data-col">
              Reach
              <td className="tr-fight-data-primary">{reach || ''}</td>
            </div>
          </td>
          <td className="tr-fight-data-header">
            <div className="tr-fight-data-col">
              Weight
              <td className="tr-fight-data-primary">{weight || ''}</td>
            </div>
          </td>
        </tr>
        <tr className="tr-fight-data-underline" />
        <tr className="tr-fight-data-underline">
          <td className="tr-fight-data-header">Age</td>
          <td className="tr-fight-data-secondary" colSpan="2">{age || ''}</td>
        </tr>
        <tr>
          <td className="tr-fight-data-header">Hometown</td>
          <td className="tr-fight-data-secondary" colSpan="2">
            {!isEmpty(location) && location}
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderPanel = (cart, fighter, side, number) => (
    (!isEmpty(fighter))
    && (
    <Panel
      className={`${cart.isFeatured ? 'tr-fight-table-header-main' : ''}
            tr-fight-table-header tr-fight-table-header-${side}`}
      header={(
        <div className={`${!cart.isFeatured ? 'tr-fight-table-secondary' : ''}
              ${!fighter.image ? 'tr-fight-table-empty-img' : ''}
              tr-fight-table-fighter-header tr-fight-table-fighter-header-${side}`}
        >
          {renderHeader(fighter.image,
            fighter.image?.url,
            fighter.firstName,
            fighter.secondName,
            fighter.records)}
        </div>
          )}
      key={`${cart.id}-${number}`}
    >
      {renderTable(fighter.height,
        fighter.reach,
        fighter.weight,
        fighter.age,
        fighter.location)}
    </Panel>
    )
  );

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (button) => {
    let path = button.data?.path || button?.path;
    if (!isEmpty(button.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${button.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${button.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${button.offerIdEverflow}&${params}`;
      }
    }

    return (
      <a
        href={path}
        key={button.id}
        className="tr-fight-card-trigger-buttons-btn"
        target={(button.data?.openInNewWindow || button.openInNewWindow) && '_blank'}
      >
        {button.data?.label || button.label}
      </a>
    );
  };

  return (
    !isEmpty(content)
    && (
      <div className="tr-fight-card-page">
        <Container className="tr-fight-card-container">
          <div className="tr-fight-card-top">
            <div className="tr-fight-card-top-dash" />
            <div className="tr-fight-card-top-background-text">
              <Marquee speed={marqueeSpeed}>
                <p className="search-page-background-text">
                  {(`${content.backgroundText} ` || 'FIGHT CARD').repeat(30)}
                </p>
              </Marquee>
            </div>
            <div className="tr-fight-card-top-briefing">
              <div className="tr-fight-card-top-briefing-time">
                {content.date || ''}
                <span className="tr-fight-card-top-briefing-time-timezone">
                  {content.dateTimezone || ''}
                </span>
              </div>
              <div className="tr-fight-card-top-briefing-name">
                {content.newTitle || ''}
              </div>
            </div>
          </div>

          <div className="tr-fight-card-trigger">
            <div className="tr-fight-card-trigger-location">
              {content.location || ''}
            </div>
            <div className="tr-fight-card-trigger-buttons">
              {!isEmpty(content.buttons) && content.buttons.map((button) => (
                !isEmpty(button)
                && ((!isEmpty(button.data) && !isEmpty(button.data.path)) || !isEmpty(button.path))
                && ((!isEmpty(button.data) && !isEmpty(button.data.label)) || !isEmpty(button.label))
                && (
                  <div
                    role="button"
                    onKeyDown={null}
                    tabIndex={0}
                    onClick={async () => {
                      await window.EF.click({
                        offer_id: button.offerIdEverflow,
                        affiliate_id: window.EF.urlParameter('affid') || 1643,
                        gclid: window.EF.urlParameter('gclid'),
                        fbclid: window.EF.urlParameter('fbclid'),
                        sub1: window.EF.urlParameter('utm_content'),
                        sub2: window.EF.urlParameter('utm_term'),
                        sub3: window.EF.urlParameter('utm_campaign'),
                      });
                    }}
                  >
                    {renderButton(button)}
                  </div>
                )
              ))}
            </div>
          </div>

          <div className="tr-divider">
            <div className="tr-divider-blue">{content.leftCornerName || 'RED CORNER'}</div>
            <div className="tr-divider-red">{content.rightCornerName || 'BLUE CORNER'}</div>
          </div>

          {content.cards && content.cards.map((card, index) => (
            !isEmpty(card)
            && (
            <div key={card.id} className="tr-cards">
              <div className="tr-fight-card-event">
                <p className="tr-fight-card-event-rounds">
                  {`${card.rounds} rounds`}
                </p>
                <p className="tr-fight-card-event-name">{card.title}</p>
              </div>

              <div className="tr-fight-table">
                <div
                  className="tr-fight-table-expand-all"
                  role="presentation"
                  onClick={toggleAll([`${card.id}-1`, `${card.id}-2`], index)}
                >
                  <div className="tr-fight-table-expand-all-content">
                    <div className="tr-fight-table-vs-svg">
                      {!card.isFeatured ? <SvgIcon mode="VS2" /> : <SvgIcon mode="VS" />}
                    </div>
                    <div className={`${(openArrows.includes(index)) ? 'open' : ''}
                      tr-fight-table-expand-svg`}
                    >
                      <SvgIcon mode="Expand" />
                    </div>
                  </div>
                </div>

                <Collapse
                  expandIcon={() => <SvgIcon mode="ExpandSmall" color="#ffffff" />}
                  activeKey={openPanels}
                  onChange={setOpenPanels}
                  className="tr-fight-table-collapse-panel"
                >
                  {renderPanel(card, card.leftFighter, 'left', 1)}
                  {renderPanel(card, card.rightFighter, 'right', 2)}
                </Collapse>
              </div>
            </div>
            )
          ))}
        </Container>
      </div>
    )
  );
};

FightCard.propTypes = {
  content: shape().isRequired,
};

export default FightCard;
