/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const SearchIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-1245.000000, -56.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g transform="translate(0.000000, -2.000000)">
          <path
            d="M1246.5,76 C1246.91667,76 1247.27083,75.8541667 1247.5625,75.5625 L1247.5625,75.5625 L1252.64583,70.4583333 C1253.72917,71.1527778 1254.93056,71.5 1256.25,71.5 C1258.11111,71.5 1259.70139,70.8402778 1261.02083,69.5208333 C1262.34028,68.2013889 1263,66.6111111 1263,64.75 C1263,62.8888889 1262.34028,61.2986111 1261.02083,59.9791667 C1259.70139,58.6597222 1258.11111,58 1256.25,58 C1254.38889,58 1252.79861,58.6597222 1251.47917,59.9791667 C1250.15972,61.2986111 1249.5,62.8888889 1249.5,64.75 C1249.5,66.0694444 1249.84722,67.2708333 1250.54167,68.3541667 L1250.54167,68.3541667 L1245.4375,73.4375 C1245.14583,73.7291667 1245,74.0833333 1245,74.5 C1245,74.9166667 1245.14583,75.2708333 1245.4375,75.5625 C1245.72917,75.8541667 1246.08333,76 1246.5,76 Z M1256.25,69.25 C1255.01389,69.25 1253.95139,68.8125 1253.0625,67.9375 C1252.1875,67.0486111 1251.75,65.9861111 1251.75,64.75 C1251.75,63.5138889 1252.19097,62.4548611 1253.07292,61.5729167 C1253.95486,60.6909722 1255.01389,60.25 1256.25,60.25 C1257.48611,60.25 1258.54514,60.6909722 1259.42708,61.5729167 C1260.30903,62.4548611 1260.75,63.5138889 1260.75,64.75 C1260.75,65.9861111 1260.30903,67.0451389 1259.42708,67.9270833 C1258.54514,68.8090278 1257.48611,69.25 1256.25,69.25 Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

SearchIcon.defaultProps = {
  color: '#D8D8D8',
  width: 18,
  height: 18,
};

SearchIcon.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default SearchIcon;
