import React from 'react';
import { func, shape } from 'prop-types';
import { Typography } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';

const { Text } = Typography;

const SingleFeaturedBanner = ({ content, redirect }) => {
  const { data } = content;

  return !isEmpty(data?.slug) && (
    <div
      className="single-featured-banner single-banner"
      onClick={redirect(data.slug)}
      role="presentation"
    >
      <div className="single-banner-img-container">
        {!isEmpty(data.primaryImage) && !isEmpty(data.primaryImage.url) && (
          <div className="single-banner-background-wrapper">
            <img className="single-banner-background-img" src={data.primaryImage.url} alt={data.primaryImage.url} />
          </div>
        )}
        {!isEmpty(data.secondaryImage) && !isEmpty(data.secondaryImage.url) && (
          <img className="single-banner-img" src={data.secondaryImage.url} alt={data.secondaryImage.url} />
        )}
      </div>
      <div className="single-banner-content">
        <div className="single-banner-content-head">
          {!isEmpty(data.type) && (
            <Text className="single-banner-content-category text-white">{data.type.label}</Text>
          )}
          {!isEmpty(data.date) && (
            <Text className="single-banner-content-date text-white">{`${moment(data.date).format('MM.DD.YYYY')}`}</Text>
          )}
        </div>
        <h1 className="single-banner-content-title">{data.title}</h1>
        {!isEmpty(data.description) && (
          <Text className="single-banner-content-description text-grey">
            {data.description.slice(0, 101)}
            {data.description > 100 ? '...' : ''}
          </Text>
        )}
      </div>
    </div>
  );
};

SingleFeaturedBanner.propTypes = {
  content: shape().isRequired,
  redirect: func.isRequired,
};

export default SingleFeaturedBanner;
