/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const TriangleIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 5"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-508.000000, -1968.000000)"
        fill={color}
      >
        <g transform="translate(238.500000, 1944.000000)">
          <polygon
            transform="translate(275.000000, 26.500000) scale(1, -1) translate(-275.000000, -26.500000) "
            points="275 24 280 29 270 29"
          />
        </g>
      </g>
    </g>
  </svg>
);

TriangleIcon.defaultProps = {
  color: '#ED1849',
  width: 11,
  height: 5,
};

TriangleIcon.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default TriangleIcon;
