/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const Instagram = ({
  colorPrimary, width, height, background,
}) => (
  <svg width={width} height={height} viewBox="0 0 30 30" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1139.000000, -4370.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="4532" />
        <g transform="translate(0.000000, 3803.000000)">
          <rect fill={colorPrimary} x="0" y="390" width="1443" height="338" />
          <g transform="translate(1139.000000, 567.000000)" stroke={background} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.04">
            <g transform="translate(2.400000, 2.400000)">
              <path d="M25.2,18.6264 C25.2,22.2564 22.2564,25.2 18.6264,25.2 L6.5736,25.2 C2.9436,25.2 0,22.2564 0,18.6264 L0,6.5736 C0,2.9436 2.9436,0 6.5736,0 L18.6264,0 C22.2564,0 25.2,2.9436 25.2,6.5736 L25.2,18.6264 Z" />
              <path d="M19.2,12.6 C19.2,16.2456 16.2456,19.2 12.6,19.2 C8.9544,19.2 6,16.2456 6,12.6 C6,8.9544 8.9544,6 12.6,6 C16.2456,6 19.2,8.9544 19.2,12.6 Z" />
              <path d="M20.4,4.2 C20.4,4.5312 20.1312,4.8 19.8,4.8 C19.4688,4.8 19.2,4.5312 19.2,4.2 C19.2,3.8688 19.4688,3.6 19.8,3.6 C20.1312,3.6 20.4,3.8688 20.4,4.2 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Instagram.defaultProps = {
  colorPrimary: '#1D1A1D',
  width: 30,
  height: 30,
  background: '#FFFFFF',
};

Instagram.propTypes = {
  colorPrimary: string,
  width: number,
  height: number,
  background: string,
};

export default Instagram;
