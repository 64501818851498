/* eslint-disable max-len */
import React from 'react';
import { number, string } from 'prop-types';

const Play = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 34 34" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1075.000000, -607.000000)">
        <g transform="translate(-1.000000, 88.000000)">
          <g transform="translate(1076.000000, 519.000000)">
            <circle fill={color} cx="17" cy="17" r="17" />
            <polygon fill="#FFFFFF" transform="translate(18.500000, 18.000000) rotate(90.000000) translate(-18.500000, -18.000000) " points="18.5 13 25 23 12 23" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Play.defaultProps = {
  color: '#ED1849',
  width: 34,
  height: 34,
};

Play.propTypes = {
  color: string,
  width: number,
  height: number,
};

export default Play;
