import React from 'react';
import { string } from 'prop-types';
import TriangleIcon from './assets/icons/Triangle';
import Expand from './assets/icons/Expand';
import VSIcon from './assets/icons/VS';
import YouTube from './assets/icons/YouTube';
import Twitter from './assets/icons/Twitter';
import Search from './assets/icons/Search';
import Instagram from './assets/icons/Instagram';
import Facebook from './assets/icons/Facebook';
import Play from './assets/icons/Play';
import Email from './assets/icons/Email';
import ArrowLeft from './assets/icons/ArrowLeft';
import ArrowRight from './assets/icons/ArrowRight';
import BurgerButton from './assets/icons/BurgerButton';
import CloseBurger from './assets/icons/CloseBurger';
import SearchReverse from './assets/icons/SearchReverse';
import Quotes from './assets/icons/Quotes';
import VS2 from './assets/icons/VS2';
import ExpandSmall from './assets/icons/ExpandSmall';
import Podcast from './assets/icons/Podcast';
import ArrowNext from './assets/icons/ArrowNext';

const SvgIcon = ({ mode, color, className }) => {
  switch (mode) {
    case 'Triangle':
      return <TriangleIcon />;
    case 'Expand':
      return <Expand />;
    case 'VS':
      return <VSIcon color={color} />;
    case 'YouTube':
      return <YouTube />;
    case 'Twitter':
      return <Twitter color={color} />;
    case 'Search':
      return <Search color={color} />;
    case 'Instagram':
      return <Instagram />;
    case 'Facebook':
      return <Facebook />;
    case 'Play':
      return <Play />;
    case 'Email':
      return <Email />;
    case 'ArrowLeft':
      return <ArrowLeft color={color} />;
    case 'ArrowRight':
      return <ArrowRight color={color} />;
    case 'BurgerButton':
      return <BurgerButton />;
    case 'CloseBurger':
      return <CloseBurger />;
    case 'SearchReverse':
      return <SearchReverse />;
    case 'Quotes':
      return <Quotes className={className} />;
    case 'VS2':
      return <VS2 />;
    case 'ExpandSmall':
      return <ExpandSmall color={color} />;
    case 'Podcast':
      return <Podcast color={color} />;
    case 'ArrowNext':
      return <ArrowNext />;
    default:
      return null;
  }
};

SvgIcon.defaultProps = {
  color: '',
  className: '',
};

SvgIcon.propTypes = {
  mode: string.isRequired,
  color: string,
  className: string,
};

export default SvgIcon;
