import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { arrayOf, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import NewsPagination from '../../stories/NewsPagination';
import CategoryFilter from '../CategoryFilter';
import NewsList from '../NewsList';
import { searchResultsPerPage } from '../../utils/constants';

const NewsContent = ({ content, data, eventBannersBetweenNews }) => {
  const uniqueNews = content?.news
    ? data.news.filter((news) => !content.news
      .some((mainNews) => mainNews.data?.id === news?.id))
    : data.news;

  const sortDate = (a, b) => {
    if (isEmpty(a.date)) return 1;
    if (moment(a?.date) < moment(b?.date)) return 1;
    return -1;
  };

  const [newsToShow, setNewsToShow] = useState(uniqueNews.sort(sortDate));
  const [page, setPage] = useState(1);
  const [pageNews, setPageNews] = useState([]);
  const itemsPerPage = data.numberOfNews || searchResultsPerPage;

  const navigateToNews = (path) => () => {
    navigate(path);
  };

  const handleNewsCategory = (category) => {
    if (category === 'all') {
      setNewsToShow(uniqueNews);
    } else {
      setNewsToShow(uniqueNews.filter((news) => !isEmpty(news) && news.type.label === category));
    }
  };

  const handleDropdownCategories = (categoryId) => {
    if (isEmpty(categoryId)) {
      setNewsToShow(uniqueNews);
    } else {
      const category = uniqueNews.find((oneNews) => oneNews.id === categoryId)?.type.label;
      setNewsToShow(uniqueNews.filter((news) => !isEmpty(news) && news.type.label === category));
    }
  };

  useEffect(() => {
    if (!isEmpty(newsToShow)) {
      if (newsToShow.length > itemsPerPage) {
        const startIndex = (page - 1) * itemsPerPage;
        const newArr = [...newsToShow];
        const news = newArr.splice(startIndex, itemsPerPage);
        setPageNews(news);
      } else setPageNews(newsToShow);
    } else setPageNews([]);
  }, [page, newsToShow]);

  const handlePageChange = (e) => {
    setPage(e);
  };

  return (
    <>
      <CategoryFilter
        filterItems={uniqueNews}
        changeCategory={handleNewsCategory}
        handleDropdownCategories={handleDropdownCategories}
        isEvent={data.isEvent}
        eventTitle={data.eventTitle}
      />
      <NewsList
        news={pageNews}
        navigateToNews={navigateToNews}
        cardClass="news-page-card"
        isEvent={data.isEvent}
        banners={eventBannersBetweenNews || []}
      />
      {!isEmpty(newsToShow) && newsToShow.length > itemsPerPage && (
        <NewsPagination
          total={newsToShow.length}
          pageSize={itemsPerPage}
          showLessItems
          clickPagination={handlePageChange}
        />
      )}
    </>
  );
};

NewsContent.propTypes = {
  data: shape().isRequired,
  content: shape().isRequired,
  eventBannersBetweenNews: arrayOf(shape()),
};

NewsContent.defaultProps = {
  eventBannersBetweenNews: [],
};

export default NewsContent;
