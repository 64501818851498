import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import {
  Menu, Drawer, List,
} from 'antd';
import { isEmpty } from 'lodash';
import { shape } from 'prop-types';
import SvgIcon from '../../stories/SvgIcon';
import Container from '../Container';

const HeaderContent = ({ data }) => {
  const headerData = data;
  const menuItems = headerData?.links;
  const logoTriller = headerData?.logo?.url || '';
  const svgLinksData = headerData?.socialLinks;

  const handleIconClick = (event) => {
    event.preventDefault();
    navigate('/search');
  };

  const [visible, setVisible] = useState(false);

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = (item) => {
    let path = item.linkData.data?.path || item?.linkData.path;

    if (!isEmpty(item.linkData.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${item.linkData.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${item.linkData.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${item.linkData.offerIdEverflow}&${params}`;
      }
    }

    return (
      <Link
        to={path}
        rel="canonical"
        className={
          `tr-button tr-button-header  
          ${path === (item.linkData.data?.path || item.linkData.path) ? 'active' : ''
          } 
      ${item.isRed ? 'link-red' : ''}`
        }
        target={(item.linkData.data?.openInNewWindow || item.linkData.openInNewWindow) && '_blank'}
      >
        {item.linkData.label || item.linkData.data?.label}
      </Link>
    );
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const navigateHomepage = () => {
    navigate('/');
  };

  const renderMenuItems = () => {
    const path = typeof window !== 'undefined' && window.location.pathname;
    const list = !isEmpty(menuItems) && menuItems.map((item) => {
      const everlowClick = async () => {
        await window.EF.click({
          offer_id: item.linkData.offerIdEverflow,
          affiliate_id: window.EF.urlParameter('affid') || 1643,
          gclid: window.EF.urlParameter('gclid'),
          fbclid: window.EF.urlParameter('fbclid'),
          sub1: window.EF.urlParameter('utm_content'),
          sub2: window.EF.urlParameter('utm_term'),
          sub3: window.EF.urlParameter('utm_campaign'),
        });

        return null;
      };
      return (((!isEmpty(item.linkData)
            && !isEmpty(item.linkData.data)
            && !isEmpty(item.linkData.data.path))
          || (!isEmpty(item.linkData)
            && !isEmpty(item.linkData.path)
            && !isEmpty(item.linkData.label)))
        && (
          <Menu.Item
            key={item.linkData.id}
            className={`${path === (item.linkData.data?.path || item.linkData.path) ? 'active' : ''}`}
          >
            <div role="button" onClick={everlowClick} onKeyDown={everlowClick} tabIndex={0}>
              {renderButton(item)}
            </div>
          </Menu.Item>

        ));
    });

    return list;
  };

  return (
    <Container>
      <div className="tr-header-container">
        <div className="tr-header-logo">
          <Link to="/">
            <img src={logoTriller} alt="logo" />
          </Link>
        </div>
        <div className="tr-header-mobile-hidden">
          <Menu mode="horizontal">
            {renderMenuItems()}
          </Menu>
          <div className="tr-header-search" role="presentation" onClick={handleIconClick}>
            <SvgIcon mode="Search" color="white" />
          </div>
        </div>

        <div className="tr-header-mobile-visible">
          <div className="burger-wrapper" role="presentation" onClick={showDrawer}>
            <SvgIcon mode="BurgerButton" color="white" />
          </div>
          <button type="button" className="header-search-btn-mobile" onClick={handleIconClick}>
            <SvgIcon mode="SearchReverse" />
          </button>
          <Drawer
            closeIcon={<SvgIcon mode="CloseBurger" />}
            placement="right"
            onClose={onClose}
            visible={visible}
            className="tr-header-drawer"
          >
            <div className="tr-header-logo" role="presentation" onClick={navigateHomepage}>
              <img src={logoTriller} alt="logo" />
            </div>
            <Menu>
              {renderMenuItems()}
            </Menu>
            {!isEmpty(svgLinksData) && (
            <div className="header-svg-icons">
              <List
                dataSource={svgLinksData}
                renderItem={(item) => (!isEmpty(item) && !isEmpty(item.socialLink) && (
                  <List.Item>
                    <a href={item.socialLink.path}>
                      {!isEmpty(item.socialLink.logo) && (
                        <img
                          src={item.socialLink.logo.url}
                          alt={item.label || item.socialLink.label}
                        />
                      )}
                    </a>
                  </List.Item>
                ))}
              />
            </div>
            )}
          </Drawer>
        </div>
      </div>
    </Container>
  );
};

HeaderContent.propTypes = {
  data: shape().isRequired,
};

export default HeaderContent;
