import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { navigate } from 'gatsby';

import ButtonGroup from '../../../stories/ButtonGroup';
import EventsList from '../../EventsList/EventsList';
import Container from '../../Container';

const { Text } = Typography;

const HeroEventFirst = ({ content }) => {
  const { bannerLinks, relatedEvent } = content;
  const [firstButton, secondButton] = bannerLinks;
  let singleButton;
  if (!isEmpty(firstButton) && isEmpty(secondButton)) singleButton = firstButton;
  if (isEmpty(firstButton) && !isEmpty(secondButton)) singleButton = secondButton;

  const navigateToEvent = (path) => () => {
    navigate(path);
  };

  const [affiliateId, setAffiliateId] = useState(1643);
  const [params, setParams] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let search = window.location.search.replace('?', '') || '';
      if (!isEmpty(search) && search.includes('affid')) {
        const utmSource = window.EF.urlParameter('affid') || 1643;
        search = `${search}&utm_source=${utmSource}`;
      } else {
        search = `${search}&affid=${affiliateId}&utm_source=${affiliateId}`;
      }
      setAffiliateId(window.EF.urlParameter('affid') || 1643);
      setParams(search);
      setIsLoading(false);
    }
  }, []);

  const renderButton = () => {
    let path = singleButton.data?.path || singleButton?.path;
    if (!isEmpty(singleButton.offerIdEverflow)) {
      if (path.includes('?')) {
        const index = path.indexOf('?') - 1;
        if (path[index] === '/') {
          path = path.substring(0, index) + path.substring(index + 1);
        }
        path = `${path}&oid=${singleButton.offerIdEverflow}&${params}`;
      } else if (path[path.length - 1] === '/') {
        path = `${path.slice(0, -1)}?oid=${singleButton.offerIdEverflow}&${params}`;
      } else {
        path = `${path}?oid=${singleButton.offerIdEverflow}&${params}`;
      }
    }

    return (
      <a
        className="tr-button tr-button-group-btn"
        target={(singleButton.data?.openInNewWindow || singleButton.openInNewWindow) && '_blank'}
        href={path}
      >
        {singleButton.label || singleButton.data?.label}
      </a>
    );
  };

  return (
    <Container id="hero-news-container">
      <div className="hero-event-first event-first ">
        {!isEmpty(relatedEvent)
          && !isEmpty(relatedEvent.data)
          && !isEmpty(relatedEvent.data.slug)
          && !isEmpty(relatedEvent.data.image)
          && !isEmpty(relatedEvent.data.image.url)
          && (
            <div
              className="event-first-img-container "
              onClick={navigateToEvent(relatedEvent.data.slug)}
              role="presentation"
            >
              <img className="event-first-img" src={relatedEvent.data.image.url} alt={relatedEvent.data.image.url} />
            </div>
          )}
        <div className="event-first-content ">
          {!isEmpty(relatedEvent) && !isEmpty(relatedEvent.data) && (
            <>
              <div className="event-first-info-head">
                <Text className="event-first-info-day">{relatedEvent.data.dateDayOfWeek}</Text>
                <Text className="event-first-info-month">{relatedEvent.data.dateMonth}</Text>
                <Text className="event-first-info-date text-white">{relatedEvent.data.date}</Text>
                <Text className="event-first-info-time">{relatedEvent.data.dateClock}</Text>
                <p className="event-first-info-timezone">{relatedEvent.data.dateTimezone}</p>
              </div>
              <h1 className="event-first-title">{relatedEvent.data.title}</h1>
            </>
          )}
          {!isEmpty(relatedEvent)
            && !isEmpty(relatedEvent.data)
            && !isEmpty(relatedEvent.data.description)
            && (
              <Text className="event-first-description text-grey">
                {relatedEvent.data.description.slice(0, 125)}
                {relatedEvent.data.description.length > 124 ? '...' : ''}
              </Text>
            )}
          {!isEmpty(secondButton && firstButton)
            && ((!isEmpty(firstButton.data.path) && !isEmpty(firstButton.data)) || !isEmpty(firstButton.path))
            && ((!isEmpty(secondButton.data.path) && !isEmpty(secondButton.data)) || !isEmpty(secondButton.path))
            && (!isEmpty(secondButton.label) || (!isEmpty(secondButton.data) && !isEmpty(secondButton.data.label)))
            && (!isEmpty(firstButton.label) || (!isEmpty(firstButton.data) && !isEmpty(firstButton.data.label)))
            && (
              <div className="event-first-button-double tr-button-group">
                <ButtonGroup
                  mode="filled-light"
                  labelPrimary={firstButton.label || firstButton.data?.label}
                  hrefPrimary={firstButton.data?.path || firstButton.path}
                  openInNewWindowPrimary={firstButton.data?.openInNewWindow || firstButton.openInNewWindow}
                  labelSecondary={secondButton.label || secondButton.data.label}
                  hrefSecondary={secondButton.data?.path || secondButton.path}
                  openInNewWindowSecondary={secondButton.data?.openInNewWindow || secondButton.openInNewWindow}
                  offerIdEverflowPrimary={firstButton.offerIdEverflow}
                  offerIdEverflowSecondary={secondButton.offerIdEverflow}
                  onClickPrimary={async () => {
                    await window.EF.click({
                      offer_id: firstButton.offerIdEverflow,
                      affiliate_id: window.EF.urlParameter('affid') || 1643,
                      gclid: window.EF.urlParameter('gclid'),
                      fbclid: window.EF.urlParameter('fbclid'),
                      sub1: window.EF.urlParameter('utm_content'),
                      sub2: window.EF.urlParameter('utm_term'),
                      sub3: window.EF.urlParameter('utm_campaign'),
                    });
                  }}
                  onClickSecondary={async () => {
                    await window.EF.click({
                      offer_id: secondButton.offerIdEverflow,
                      affiliate_id: window.EF.urlParameter('affid') || 1643,
                      gclid: window.EF.urlParameter('gclid'),
                      fbclid: window.EF.urlParameter('fbclid'),
                      sub1: window.EF.urlParameter('utm_content'),
                      sub2: window.EF.urlParameter('utm_term'),
                      sub3: window.EF.urlParameter('utm_campaign'),
                    });
                  }}
                />
              </div>
            )}
          {!isEmpty(singleButton)
            && ((!isEmpty(singleButton.data.path) && !isEmpty(singleButton.data)) || !isEmpty(singleButton.path))
            && (!isEmpty(singleButton.label) || (!isEmpty(singleButton.data) && !isEmpty(singleButton.data.label)))
            && (
              <div
                className="event-first-button-single tr-button-group"
                role="button"
                onKeyDown={null}
                tabIndex={0}
                onClick={async () => {
                  await window.EF.click({
                    offer_id: singleButton.offerIdEverflow,
                    affiliate_id: window.EF.urlParameter('affid') || 1643,
                    gclid: window.EF.urlParameter('gclid'),
                    fbclid: window.EF.urlParameter('fbclid'),
                    sub1: window.EF.urlParameter('utm_content'),
                    sub2: window.EF.urlParameter('utm_term'),
                    sub3: window.EF.urlParameter('utm_campaign'),
                  });
                }}
              >
                {renderButton()}
              </div>
            )}
        </div>
      </div>
      {!isEmpty(relatedEvent)
        && !isEmpty(relatedEvent.data)
        && !isEmpty(relatedEvent.data.additionalNews)
        && (
          <EventsList
            events={relatedEvent.data.additionalNews}
            newsBackgroundImage={content.newsBackgroundImage}
            button={content.button}
            navigateToEvent={navigateToEvent}
          />
        )}
    </Container>
  );
};

HeroEventFirst.propTypes = {
  content: shape().isRequired,
};

export default HeroEventFirst;
